import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover2 } from '@blueprintjs/popover2';
import { useToggle } from 'react-use';

import Chips from '@setproduct-ui/core/Chips/Chips';
import TextField from 'components/fields/TextField';
import { Typography } from 'components/Typography';
import { CaretDownIcon } from 'assets/icons';

import './style.scss';

const DropdownField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  placeholder,
  label,
  disabled,
  options = [],
  styles = {},
  caretOptions: {
    visible = true,
    closeColor = '#9696A0',
    openColor = '#4A4B57',
  } = {},
  isRequired,
  translateOptions = true,
  readOnly,
  clearButton = false,
  dense,
  fieldClassNames = {},
  children,
  newDesign,
  fieldError,
  fieldTouched,
  showErrorMessage = true,
}) => {
  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  const textFieldRef = useRef();

  const fieldClass = classNames(`dropdown-field ${fieldClassNames.container}`, {
    'dropdown-field_disabled': disabled,
    'dropdown-field_dense': dense,
    'dropdown-field_new-design': newDesign,
  });
  const labelClass = classNames('dropdown-field__label', {
    'dropdown-field__label_required': isRequired,
    'dropdown-field__label_new-design': newDesign,
  });
  const inputClass = classNames('dropdown-field__content__input', {
    'dropdown-field__content__input_new-design': newDesign,
  });

  const [searchValue, setSearchValue] = useState(value || '');
  const [isAll, setIsAll] = useState(true);

  const onTargetClick = () => {
    if (!disabled && !readOnly) {
      setIsOpenMenu();
    }
  };
  const onSearchChange = (val) => {
    setIsAll(false);
    setSearchValue(val);
  };
  const handleChange = (selectedItem) => {
    if (setFieldValue) {
      setFieldValue(name, selectedItem.value);
    } else {
      onChange(selectedItem.value);
    }
    setSearchValue(selectedItem.label);
    setIsAll(true);
    setIsOpenMenu(false);
  };
  const onClose = (e) => {
    if (e?.target !== textFieldRef?.current) {
      setIsOpenMenu(false);
    }
  };
  const onClosed = () => {
    textFieldRef.current?.blur?.();
    setIsOpenMenu(false);
  };
  const onFocus = () => {
    if (!disabled && !readOnly) {
      setIsOpenMenu(true);
    }
  };
  const onClear = () => {
    handleChange({ value: undefined, label: '' });
  };

  const selectedOption = useMemo(
    () =>
      options.find(I => I.value === value)
          || (value
            ? {
              value,
              label: `Missed option: ${value}`,
            } : undefined),
    [value, options],
  );
  const displayOptions = useMemo(() => {
    if (isAll || !searchValue) {
      return options;
    }
    return options.filter(I =>
      I.label?.toLowerCase()?.includes(searchValue?.toLowerCase()));
  }, [options, searchValue, isAll]);

  useEffect(() => {
    if (!isOpenMenu && selectedOption?.label !== searchValue) {
      setSearchValue(t(selectedOption?.label) || '');
      setIsAll(true);
    }
  }, [selectedOption, isOpenMenu]);

  const iconClassName = classNames('dropdown-field__content__icon', {
    'dropdown-field__content__icon_open': isOpenMenu,
    'dropdown-field__content__icon_disabled': disabled,
  });

  return (
    <Popover2
      placement="bottom-start"
      transitionDuration={0}
      isOpen={isOpenMenu}
      interactionKind="click"
      onClose={onClose}
      onClosed={onClosed}
      autoFocus={false}
      portalClassName="portal-content"
      minimal
      usePortal
      lazy
      renderTarget={({
        ref, isOpen, className, ...rest
      }) => (
        <div
          {...rest}
          ref={ref}
          style={styles.container}
          className={fieldClass}
        >
          {
            label && (
              <Typography className={labelClass} style={styles.label}>
                {label}
              </Typography>
            )
          }
          <div
            className="dropdown-field__content"
            style={styles.content}
          >
            <TextField
              classNames={{ container: classNames(inputClass, className) }}
              value={translateOptions ? t(searchValue) : searchValue}
              onChange={onSearchChange}
              placeholder={placeholder}
              disabled={disabled}
              styles={{ container: { ...(styles.input || {}), ...(selectedOption?.style || {}) } }}
              clearButton={clearButton}
              showErrorIcon={false}
              fieldError={fieldError || errors[name]}
              fieldTouched={fieldTouched || touched[name]}
              readOnly={readOnly}
              dense={dense}
              onClear={onClear}
              onFocus={onFocus}
              inputRef={textFieldRef}
              showErrorMessage={showErrorMessage}
              data-testid={`${window.location.pathname}/dropdown-field/${name}`}
              leftElement={selectedOption?.icon ? (
                <div className="dropdown-field__left-element-wrapper">
                  {selectedOption?.icon}
                </div>
              ) : null}
            />
            {
              visible && !disabled && !(clearButton && isOpenMenu && value) && (
                <CaretDownIcon
                  className={iconClassName}
                  onClick={onTargetClick}
                  fill={isOpenMenu ? openColor : closeColor}
                />
              )
            }
          </div>
          {children}
        </div>
      )}
      content={(
        <div
          className="dropdown-field__content__overlay"
          style={{
            ...(styles.overlay && styles.overlay),
            width: textFieldRef.current?.offsetWidth,
          }}
        >
          {
            displayOptions.map(I => (
              <button
                className="dropdown-field__content__overlay__item"
                onClick={() => handleChange(I)}
                type="button"
                name="country"
                key={I.value}
                style={{
                  ...(styles.item && styles.item),
                  ...(I.style && I.style),
                }}
                disabled={I.disabled}
                data-testid={`${window.location.pathname}/dropdown-field/${name}/${translateOptions ? t(I.label) : I.label}/${I.value}`}
              >
                {translateOptions ? t(I.label) : I.label}
                {I.labelChip && (
                  <Chips
                    type="dense"
                    view="smooth"
                    round={false}
                    {...I.labelChip}
                  />
                )}
              </button>
            ))
          }
        </div>
      )}
    />
  );
};

export default DropdownField;
