import React from 'react';

import { CustomColumnsView } from 'components/blocks';
import { AUDIT_COLUMNS } from 'consts/columns';
import { useAccountsApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';

import styles from './AuditScreen.module.scss';

const AuditScreen = ({ screenRef }) => {
  const {
    getApiCalls,
    apiCalls,
    apiCallsMeta,
    isPendingGetApiCalls,
    lastUpdatedGetApiCalls,
  } = useAccountsApi();

  const {
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getApiCalls,
  });

  const getRowHeight = () => 50;

  return (
    <div className={styles.container}>
      <Table
        tableHeader="TABS.AUDIT"
        openCustomColumns={openCustomViewDrawer}
        columnDefs={AUDIT_COLUMNS}
        rowData={apiCalls}
        showFilters={false}
        limit={limit}
        total={apiCallsMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetApiCalls || !lastUpdatedGetApiCalls}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        getRowHeight={getRowHeight}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={AUDIT_COLUMNS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
    </div>
  );
};

export default AuditScreen;
