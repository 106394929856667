import React, {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getDiffFromNow, round } from 'helpers';
import { DatePickerField, DropdownField } from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { PACKS_ENABLED_SCHEMA } from 'consts/validationSchemas';
import { usePacksApi } from 'hooks/api';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import RadioButtonField from 'components/fields/RadioButtonField';
import ChargePrice from 'components/blocks/ChargePrice';
import { PACKS_BILLING_TYPES } from 'consts';

import './style.scss';

const PacksEnabledForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  agreementsOptions,
  servicesOptions,
  timezonesOptions,
}) => {
  const clientTimezone = dayjs.tz.guess();
  // styles
  const fieldStyle = ({
    width,
    marginTop,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });
  const agreementsDropdownStyles = {
    container: {
      marginTop: 24,
    },
    overlay: {
      maxHeight: 180,
    },
  };
  const servicesDropdownStyles = {
    overlay: {
      maxHeight: 180,
    },
  };
  const checkBoxStyles = {
    container: {
      marginLeft: 0,
      marginTop: 24,
    },
  };
  const timezoneDropdownFieldStyles = {
    container: {
      width: 268,
    },
    overlay: {
      bottom: '100%',
      right: 0,
      top: 'initial',
      left: 'initial',
    },
  };
  // hooks
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getPackPrice } = usePacksApi();
  const [selectedService, setSelectedService] = useState({});
  const [currentEffectiveFrom, setCurrentEffectiveFrom] = useState(initialValues?.effectiveFrom ? initialValues.effectiveFrom : null);
  const [currentEffectiveTill, setCurrentEffectiveTill] = useState(initialValues?.effectiveTill ? initialValues.effectiveTill : null);
  const [currentEffectiveFromTz, setCurrentEffectiveFromTz] = useState(clientTimezone);
  const [currentEffectiveTillTz, setCurrentEffectiveTillTz] = useState(clientTimezone);
  const [packPrice, setPackPrice] = useState();
  const { activeTab } = useSelector(state => state?.states.routes);
  // memed vars
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);
  const isAdd = useMemo(() => mode === 'add', [mode]);

  const isDisabledEffective = (target) => {
    const now = dayjs().toDate();
    if (isView) {
      return true;
    }
    if (isAdd) {
      return false;
    }
    if (isNull(target)) {
      return false;
    }
    return !(target > now);
  };

  const isFromDisabled = useMemo(
    () =>
      isDisabledEffective(initialValues.effectiveFrom || currentEffectiveFrom),
    [currentEffectiveFrom, initialValues, mode],
  );

  const isTillDisabled = useMemo(
    () =>
      isDisabledEffective(initialValues.effectiveTill || currentEffectiveTill),
    [currentEffectiveTill, initialValues, mode],
  );

  const isFromTzDisabled = useMemo(() => {
    const now = dayjs().toDate();
    return isView ? true : !(currentEffectiveFrom && currentEffectiveFrom > now);
  }, [currentEffectiveFrom, initialValues, isView, isAdd, isEdit]);

  const agreementsByCurrency = useMemo(() => {
    if (selectedService?.currency) {
      return agreementsOptions.filter(
        ({ currency }) => currency === selectedService.currency,
      );
    }
    return agreementsOptions;
  }, [selectedService, servicesOptions, agreementsOptions]);

  // functions
  const changeToEdit = () => changeMode('edit');

  const onSubmitModify = ({ effectiveFrom, effectiveTill, ...values }) => {
    const {
      id,
      agreementId,
      serviceId,
      billing,
    } = values;
    let effectiveFromWithTz;
    let effectiveTillWithTz;
    if (isFromDisabled) {
      effectiveFromWithTz = dayjs(effectiveFrom)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    } else {
      const fromDiff = getDiffFromNow(effectiveFrom);
      if (fromDiff < 0) {
        effectiveFromWithTz = 'now';
      } else if (isEdit) {
        effectiveFromWithTz = dayjs(effectiveFrom)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (isAdd) {
        effectiveFromWithTz = dayjs(effectiveFrom)
          .tz(currentEffectiveFromTz, true)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    if (effectiveTill) {
      if (isEdit) {
        effectiveTillWithTz = dayjs(effectiveTill)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (isAdd) {
        effectiveTillWithTz = dayjs(effectiveTill)
          .tz(currentEffectiveTillTz, true)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    const data = {
      effectiveFrom: effectiveFromWithTz === 'now' ? effectiveFromWithTz : `${effectiveFromWithTz}Z`,
      effectiveTill: effectiveTillWithTz ? `${effectiveTillWithTz}Z` : null,
      agreementId,
      serviceId,
    };
    if (id) {
      data.id = id;
    } else {
      data.billing = billing;
    }
    onSubmit(data);
  };

  const onChangeFormValues = (values) => {
    if (values.serviceId && values.agreementId && values.effectiveFrom && values.billing && isAdd) {
      const sendPoint = getDiffFromNow(values.effectiveFrom) < 0;

      getPackPrice({
        params: {
          serviceId: values.serviceId,
          agreementId: values.agreementId,
          effectiveFrom: sendPoint ? 'now' : `${dayjs(values.effectiveFrom).format('YYYY-MM-DDTHH:mm:ss')}Z`,
          effectiveTill: values.effectiveTill
            ? `${dayjs(values.effectiveTill).format('YYYY-MM-DDTHH:mm:ss')}Z`
            : undefined,
        },
        successCallback: (res) => {
          setPackPrice({
            price: `${round({ num: res.value })} ${res.agreementCurrency}`,
            service: res.serviceName,
            date: `Effective: ${dayjs(res.effectiveFrom).format('DD.MM.YYYY HH:mm:ss')}${res.effectiveTill
              ? `- ${dayjs(res.effectiveTill).format('DD.MM.YYYY HH:mm:ss')}` : ''}`,
          });
        },
        errorCallback: ({ key } = {}) => {
          setPackPrice({ errorMessage: t(key) || 'Unknown error' });
        },
      });
    } else if (packPrice) {
      setPackPrice(undefined);
    }
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }
    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  const onChangeEffectiveFromTz = ({ value }) => {
    setCurrentEffectiveFromTz(value);
    if (currentEffectiveFrom) {
      let date;
      if (isAdd) {
        date = dayjs.utc(currentEffectiveFrom).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        date = dayjs(currentEffectiveFrom).format('YYYY-MM-DDTHH:mm:ss');
      }
      const dateInTimezone = dayjs(date)
        .tz(value)
        .utc(true)
        .toDate();
      formRef.current?.setFieldValue('effectiveFrom', dateInTimezone);
    }
  };

  const onChangeEffectiveTillTz = ({ value }) => {
    setCurrentEffectiveTillTz(value);
    if (currentEffectiveTill) {
      let date;
      if (isAdd) {
        date = dayjs.utc(currentEffectiveTill).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        date = dayjs(currentEffectiveTill).format('YYYY-MM-DDTHH:mm:ss');
      }
      const dateInTimezone = dayjs(date)
        .tz(value)
        .utc(true)
        .toDate();
      formRef.current?.setFieldValue('effectiveTill', dateInTimezone);
    }
  };

  const onChangeServiceId = ({ value: selectedServiceId }) => {
    const target = servicesOptions.find(
      ({ value }) => value === selectedServiceId,
    );
    setSelectedService(target);
  };

  const onChangeEffectiveFrom = (value) => {
    setCurrentEffectiveFrom(value);
  };

  const onChangeEffectiveTill = (value) => {
    setCurrentEffectiveTill(value);
  };

  useEffect(() => {
    if (initialValues?.effectiveFrom) {
      setCurrentEffectiveFrom(initialValues?.effectiveFrom);
    }
    if (initialValues?.effectiveTill) {
      setCurrentEffectiveTill(initialValues?.effectiveTill);
    }
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        effectiveFromTz: currentEffectiveFromTz,
        effectiveTillTz: currentEffectiveTillTz,
        effectiveFrom: dayjs().startOf('day').toDate(),
        effectiveTill: null,
        billing: false,
        ...initialValues,
      }}
      onSubmit={onSubmitModify}
      validationSchema={PACKS_ENABLED_SCHEMA}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="packs-enabled-form">
          <div className="packs-enabled-form__content">
            <Field
              name="serviceId"
              component={DropdownField}
              label="INSTANCES.PACKS_ENABLED.SERVICE_PACK"
              styles={servicesDropdownStyles}
              options={servicesOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="agreementId"
              component={DropdownField}
              label="INSTANCES.AGREEMENT"
              styles={agreementsDropdownStyles}
              options={agreementsByCurrency}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <div className="packs-enabled-form__row">
              <Field
                name="effectiveFrom"
                label="INSTANCES.EFFECTIVE_FROM"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                maxDate={values?.effectiveTill}
                minDate={dayjs().toDate()}
                handleChangeCallback={onChangeEffectiveFrom}
                disabled={isFromDisabled}
                isRequired
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_FROM')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
                isNow={!isView}
              />
              <Field
                name="effectiveFromTz"
                label="INSTANCES.PACKS_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled={isFromTzDisabled}
                translateOptions={false}
              />
            </div>
            <div className="packs-enabled-form__row">
              <Field
                name="effectiveTill"
                label="INSTANCES.EFFECTIVE_TILL"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                minDate={dayjs(dayjs(values?.effectiveFrom) > dayjs()
                  ? values?.effectiveFrom
                  : dayjs())
                  .add(5, 'minutes')
                  .toDate()}
                handleChangeCallback={onChangeEffectiveTill}
                disabled={isTillDisabled}
                withIndefinitely
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_TILL')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
              />
              <Field
                name="effectiveTillTz"
                label="INSTANCES.PACKS_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled={isTillDisabled || !values.effectiveTill}
                translateOptions={false}
              />
            </div>
            {!isEdit && !isView && (
              <Field
                name="billing"
                label="INSTANCES.BILLING"
                component={RadioButtonField}
                options={PACKS_BILLING_TYPES}
                styles={checkBoxStyles}
                isRequired
              />
            )}
            <ChargePrice
              style={{ marginTop: 8, marginLeft: 32 }}
              {...(packPrice && packPrice)}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            showDelete={!isFromDisabled}
            isPending={isPending}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={onChangeFormValues} />
          <OnChangeComponent field="effectiveFromTz" onChange={onChangeEffectiveFromTz} />
          <OnChangeComponent field="effectiveTillTz" onChange={onChangeEffectiveTillTz} />
          <OnChangeComponent field="serviceId" onChange={onChangeServiceId} />
        </Form>
      )}
    </Formik>
  );
};

export default PacksEnabledForm;
