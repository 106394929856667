import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import shortid from 'shortid';

import { useDictionariesApi, useServicesApi } from 'hooks/api';

import styles from './BreakdownDrawer.module.scss';

import Drawer from '../Drawer';
import Checkbox from '../Checkbox';
import { ReactComponent as SearchIcon } from '../../icons/magnifying-glass.svg';
import { ReactComponent as CirclePlus } from '../../icons/circle-plus.svg';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash-xmark.svg';

const BreakdownDrawer = ({
  isOpen,
  toggleOpen,
  resourceCellOptions,
  data,
  resourceAttributesDictionary,
  getFilteredItems,
  definitionsFiltersForChildren,
  definitionsList,
  values = {},
  getNodesListAndMakeTree,
  submitForm,
}) => {
  if (!isOpen) {
    return null;
  }
  /* eslint-disable react-hooks/rules-of-hooks */
  const { dictionariesWithLinks } = useDictionariesApi();
  const {
    patchBreakdownAttribute,
    isPendingPatchBreakdownAttribute,
  } = useServicesApi();
  const [isMassChecked, setIsMassChecked] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [customOptions, setCustomOptions] = useState([]);
  const [selectedAction, setSelectedAction] = useState('1');
  const [checkedList, setCheckedList] = useState([]);

  const title = useMemo(() => {
    const matchAttributeValueLabel = resourceCellOptions
      ?.find(option => option.value === data.matchAttributeValues[0])?.label || data.matchAttributeValues[0];

    if (data.level === 2) {
      return {
        attribute: 'Resource',
        attributeValue: matchAttributeValueLabel,
        childrenAttribute: resourceAttributesDictionary[data.childrenMatchAttributeId]?.displayName,
      };
    }

    return {
      attribute: resourceAttributesDictionary[data.matchAttributeId]?.displayName,
      attributeValue: matchAttributeValueLabel,
    };
  }, [data.matchAttributeId, data.childrenMatchAttributeId]);
  const {
    isNewDictionaryRelatedWithOld,
    dictionaryId,
  } = useMemo(() => {
    const newDictionaryId = resourceAttributesDictionary?.[values.childrenMatchAttributeId]?.dictionaryId;
    const oldDictionaryId = resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.dictionaryId;

    return {
      isNewDictionaryRelatedWithOld: dictionariesWithLinks[newDictionaryId]?.linkedDictionaries?.includes(oldDictionaryId),
      dictionaryId: newDictionaryId,
    };
  }, []);
  const displayOptions = useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, options]);

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value);
  };
  const onRadioChange = ({ target }) => {
    setSelectedAction(target.id);
    setCheckedList([]);
    setCustomOptions([]);
    setIsMassChecked(false);
  };
  const onMassCheckChange = (checked) => {
    setIsMassChecked(checked);

    if (checked) {
      setCheckedList(prev => options.reduce((acc, option) => {
        if (!prev.includes(option.value)) {
          acc.push(option.value);
        }

        return acc;
      }, []));
    } else {
      setCheckedList([]);
    }
  };
  const addCustomOption = () => {
    setCustomOptions((prev) => {
      if (prev.find(option => option.isDraft)) {
        return prev;
      }

      return [
        ...prev,
        { value: '', isDraft: true, id: shortid.generate() },
      ];
    });
  };
  const onChangeOptionCheck = (checked, value) => {
    setCheckedList(prev => (checked ? [...prev, value] : prev.filter(item => item !== value)));
  };
  const onChangeOptionValue = ({ target }, optionId) => {
    setCustomOptions(prev => prev.map((item) => {
      if (item.id === optionId) {
        return ({
          ...item,
          value: target.value,
        });
      }
      return item;
    }, []));
  };
  const onSaveOption = (optionId) => {
    setCustomOptions(prev => prev.map((item) => {
      if (item.id === optionId) {
        return ({
          ...item,
          isDraft: false,
        });
      }
      return item;
    }, []));
  };
  const onDeleteOption = optionId => setCustomOptions(prev => prev.filter(option => option.id !== optionId));
  const onSave = () => {
    if (values.priceType !== undefined && values.priceType !== null) {
      submitForm();
    } else {
      patchBreakdownAttribute({
        scopeItemId: data.id,
        body: {
          matchAttributeValuesToAdd: selectedAction === '2' ? (
            dictionaryId
              ? checkedList.map(item => String(item))
              : customOptions.reduce((acc, item) => {
                if (!item.isDraft) {
                  acc.push(item.value);
                }
                return acc;
              }, [])
          ) : [],
          mode: Number(selectedAction),
          newChildrenMatchAttributeId: values.childrenMatchAttributeId,
        },
        successCallback: getNodesListAndMakeTree,
      });
    }
  };

  useEffect(() => {
    if (values.childrenMatchAttributeId) {
      const dictionaryIdForChildren = resourceAttributesDictionary?.[values.childrenMatchAttributeId]?.dictionaryId;

      if (data.level === 2) {
        setOptions([
          ...(definitionsList[dictionaryIdForChildren] || []),
          {
            value: 'null',
            label: 'Others',
          },
        ]);
      } else if (dictionaryIdForChildren) {
        getFilteredItems({
          dictionaryId: dictionaryIdForChildren,
          body: definitionsFiltersForChildren,
          successCallback: (res) => {
            setOptions([
              ...(definitionsList[dictionaryIdForChildren]?.reduce((acc, item) => {
                if (res?.data?.some(key => key === item.value)) {
                  acc.push({
                    ...item,
                  });
                }
                return acc;
              }, []) || []),
              {
                value: 'null',
                label: 'Others',
              },
            ]);
          },
        });
      }
    }
  }, []);

  return (
    <Drawer
      closeDrawer={toggleOpen}
      onSave={onSave}
      isPending={isPendingPatchBreakdownAttribute}
      title={(
        <span>
          {`${title.attribute}: `}
          <span style={{ color: 'var(--grey100)' }}>
            {title.attributeValue}
            {' '}
            - Breakdown attribute change
          </span>
        </span>
      )}
    >
      {(values.priceType !== undefined && values.priceType !== null) && (
        <p className={styles.info}>
          {'You\'ve changed the record\'s breakdown attribute from'}
          &nbsp;
          {resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.displayName}
          &nbsp;
          to&nbsp;
          <i>nothing.</i>
          <br />
          <b>All its child records will be deleted.</b>
          <br />
          Are you sure you want to proceed?
        </p>
      )}
      {values.childrenMatchAttributeId && (
        <>
          <p className={styles.info}>
            You have changed the record`s breakdown attribute from
            <b>
              &nbsp;
              {resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.displayName}
              &nbsp;
            </b>
            to
            <b>
              &nbsp;
              {resourceAttributesDictionary?.[values.childrenMatchAttributeId]?.displayName}
              ,&nbsp;
            </b>
            but the record already has
            <b>
              &nbsp;
              {resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.displayName}
              &nbsp;
            </b>
            child records.
            <br />
            <span>
              What do you want to do with those child records?
            </span>
          </p>
          <div className={styles.radioGroup}>
            <div className={cx(styles.radio, styles.radio_red)}>
              <input
                type="radio"
                name="action"
                id="1"
                checked={selectedAction === '1'}
                onChange={onRadioChange}
              />
              <label htmlFor="1" className={styles.radioLabel} style={{ color: 'var(--red70)' }}>
                Delete all child records with all sub-records
              </label>
            </div>
            {isNewDictionaryRelatedWithOld ? (
              <div className={styles.radio}>
                <input
                  type="radio"
                  name="action"
                  id="3"
                  checked={selectedAction === '3'}
                  onChange={onRadioChange}
                />
                <label htmlFor="3" className={styles.radioLabel}>
                  Distribute
                  <b>{resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.displayName}</b>
                  child records among corresponding
                  <b>{resourceAttributesDictionary?.[values.childrenMatchAttributeId]?.displayName}</b>
                  records
                </label>
              </div>
            ) : (
              <div className={styles.radio}>
                <input
                  type="radio"
                  name="action"
                  id="2"
                  checked={selectedAction === '2'}
                  onChange={onRadioChange}
                />
                <label htmlFor="2" className={styles.radioLabel}>
                  Clone
                  <b>{resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.displayName}</b>
                  child records under each
                  <b>{resourceAttributesDictionary?.[values.childrenMatchAttributeId]?.displayName}</b>
                  selected
                </label>
              </div>
            )}
          </div>
          {selectedAction === '2' && (
            <div className={styles.card}>
              {dictionaryId && (
                <div className={styles.searchRow}>
                  <Checkbox
                    checked={isMassChecked}
                    onChange={onMassCheckChange}
                  />
                  <input
                    type="text"
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder="Search..."
                  />
                  <SearchIcon />
                </div>
              )}
              <div className={styles.list}>
                {dictionaryId ? (
                  displayOptions?.map(option => (
                    <label
                      htmlFor={`checkbox-${option.value}`}
                      key={option.value}
                      className={styles.listItem}
                    >
                      <Checkbox
                        id={`checkbox-${option.value}`}
                        checked={checkedList.includes(option.value)}
                        onChange={checked => onChangeOptionCheck(checked, option.value)}
                      />
                      <span
                        className={styles.label}
                        style={option.value === 'null' ? { color: 'var(--grey50)' } : undefined}
                      >
                        {option.label}
                      </span>
                    </label>
                  ))
                ) : (
                  <>
                    {customOptions.map(option => (
                      <div
                        key={option.id}
                        className={styles.inputWrapper}
                      >
                        <input
                          type="text"
                          value={!option.isDraft && !option.value ? 'Others' : option.value}
                          onChange={e => onChangeOptionValue(e, option.id)}
                          readOnly={!option.isDraft}
                          placeholder={'Enter value ("Others if empty")'}
                          style={!option.isDraft && !option.value ? { color: 'var(--grey50' } : undefined}
                        />
                        {option.isDraft ? (
                          !customOptions.some(item => !item.isDraft && item.value === option.value)
                          && <CheckIcon onClick={() => onSaveOption(option.id)} />
                        ) : (
                          <TrashIcon onClick={() => onDeleteOption(option.id)} />
                        )}
                      </div>
                    ))}
                    <CirclePlus
                      onClick={addCustomOption}
                      className={cx(styles.addButton, {
                        [styles.addButton_disabled]: !!customOptions.find(option => option.isDraft),
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default BreakdownDrawer;
