import React, { useRef, useMemo } from 'react';
import { Field, ErrorMessage } from 'formik';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Color from '@setproduct-ui/styles/color.module.css';

import styles from './ResourceCell.module.scss';

import { ReactComponent as CirclePlusIcon } from '../../icons/circle-plus.svg';
import { ReactComponent as CrossIcon } from '../../icons/circle-xmark.svg';
import { ReactComponent as SearchIcon } from '../../icons/magnifying-glass.svg';
import { ReactComponent as FolderPlusIcon } from '../../icons/folder-plus.svg';
import Dropdown from '../Dropdown';

const ResourceCell = ({
  level,
  options = [],
  freeInput,
  withControls,
  withSearch,
  onAdd,
  onMultiAdd,
  disabled,
  isEdit,
  addChildrenDisabled,
  zIndex,
  cellRef,
  touched,
  errors,
  title,
  searchValue,
  onChangeSearch,
  linkColor,
  siblingValues,
  isLoading,
}) => {
  const fieldStyle = {
    color: level > 2 ? undefined : 'var(--indigo70)',
    width: 225,
  };
  const freeInputStyle = {
    width: 225,
    cursor: isEdit ? undefined : 'default',
  };
  const wrapperStyle = {
    cursor: isEdit ? undefined : 'default',
  };
  const cellStyle = {
    paddingLeft: level * 24,
    zIndex,
    paddingBottom: withControls ? 6 : 2,
  };
  const linkStyle = {
    left: level * 24 - 11,
    borderColor: linkColor || 'var(--grey20)',
  };

  const searchInputRef = useRef();
  const { t } = useTranslation();

  const optionsModify = useMemo(() => {
    let optionsToReturn = options;

    if (level > 2) {
      optionsToReturn = [
        ...options,
        {
          label: 'Others',
          value: 'null',
          style: {
            color: 'var(--grey50)',
          },
        },
      ];
    }
    if (siblingValues) {
      return optionsToReturn
        .filter(I => !siblingValues.find(siblingValue => String(siblingValue) === String(I.value)));
    }

    return optionsToReturn;
  }, [options]);

  const onSearchWrapperClick = (e) => {
    e.stopPropagation();

    searchInputRef.current?.focus();
  };
  const onClearClick = (e) => {
    e.stopPropagation();
    searchInputRef.current?.blur();
    onChangeSearch({ target: { value: '' } });
  };
  const onAddClick = (e) => {
    e.stopPropagation();
    if (!addChildrenDisabled) {
      onAdd();
    }
  };
  const onMultiAddClick = (e) => {
    e.stopPropagation();
    onMultiAdd();
  };

  return (
    <div
      className={styles.cell}
      style={cellStyle}
      ref={cellRef}
      title={title}
    >
      {level > 1 && (
        <div
          style={linkStyle}
          className={styles.link}
        />
      )}
      {freeInput ? (
        <>
          <Field
            name="matchAttributeValues"
          >
            {({ field }) => (
              <input
                type="text"
                disabled={disabled}
                placeholder={t('PLACEHOLDERS.SERVICES.ENTER_OR_LEFT')}
                className={cx(styles.input, {
                  [styles.input_error]: touched.matchAttributeValues && errors.matchAttributeValues,
                })}
                style={{
                  ...freeInputStyle,
                  color: disabled && field.value === 'null' ? 'var(--grey50)' : undefined,
                }}
                {...field}
                value={field.value === 'null' ? disabled ? 'Others' : '' : field.value}
                onBlur={Function.prototype}
              />
            )}
          </Field>
          <ErrorMessage name="matchAttributeValues">
            {msg => <span className={styles.error}>{t(msg)}</span>}
          </ErrorMessage>
        </>
      ) : (
        <Field
          name="matchAttributeValues"
          component={Dropdown}
          options={optionsModify}
          placeholder={`PLACEHOLDERS.SERVICES.SELECT_${level === 1 ? 'SERVICE_DOMAIN' : 'ATTRIBUTE'}`}
          textInputStyles={fieldStyle}
          wrapperStyle={wrapperStyle}
          disabled={disabled || isLoading}
          isLoading={isLoading}
          withSearch
        />
      )}
      {withControls && (
        <div className={styles.row}>
          <CirclePlusIcon
            icon="add"
            onClick={onAddClick}
            className={cx(styles.button, Color.primary, {
              [styles.button_disabled]: addChildrenDisabled,
            })}
          />
          <FolderPlusIcon
            onClick={onMultiAddClick}
            className={cx(styles.button, Color.primary)}
          />
          {withSearch && (
            <div
              className={styles.searchWrapper}
              role="presentation"
              onClick={onSearchWrapperClick}
            >
              <SearchIcon className={cx(styles.button, Color.warning_alt)} />
              <input
                type="text"
                ref={searchInputRef}
                value={searchValue}
                onChange={onChangeSearch}
              />
              <CrossIcon className={styles.cleanButton} onClick={onClearClick} />
              <div className={styles.searchBorder} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResourceCell;
