import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { useServicesEnabledApi, useAgreementsApi, useDictionariesApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { SERVICES_ENABLED } from 'consts/columns';
import Modal from 'components/Modal';

import ServiceEnabledForm from './ServiceEnabledForm';

import './style.scss';

const ServicesEnabledScreen = ({ screenRef }) => {
  const {
    getServicesEnabled,
    deleteServiceEnabled,
    putServiceEnabled,
    postServiceEnabled,
    isPendingGetServicesEnabled,
    isPendingPutServiceEnabled,
    isPendingDeleteServiceEnabled,
    isPendingPostServiceEnabled,
    lastUpdatedGetServicesEnabled,
    servicesEnabled,
    servicesEnabledMeta,
  } = useServicesEnabledApi();
  const {
    timezones,
    servicesOptions,
    getServicesDictionary,
  } = useDictionariesApi();
  const { agreementsAll, getAgreements } = useAgreementsApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getServicesEnabled,
    deleteFunc: deleteServiceEnabled,
    onRowClickCallback: (data) => {
      const effectiveFromUTC = dayjs.utc(data.effectiveFrom).format('YYYY-MM-DDTHH:mm:ss');
      const effectiveTillUTC = dayjs.utc(data.effectiveTill).format('YYYY-MM-DDTHH:mm:ss');
      return {
        ...data,
        effectiveFrom: dayjs(effectiveFromUTC).toDate(),
        effectiveTill: data.effectiveTill === null ? null : dayjs(effectiveTillUTC).toDate(),
      };
    },
  });

  const agreementsOptions = useMemo(() => agreementsAll.map(item => ({
    value: item.id,
    label: `${item.companyName} - ${item.agreementCode} - (${item.currency})`,
    currency: item.currency,
  })), [agreementsAll]);
  const filteredServicesOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 1), [servicesOptions]);
  const timezonesOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(I => (I.areaName === v.areaName)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);

  const onSubmit = (body) => {
    (editableRecord ? putServiceEnabled : postServiceEnabled)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getServicesDictionary();
    getAgreements({ limit: 1000, storeKey: 'agreementsAll' });
  }, []);

  return (
    <div className="services-enabled-layout">
      <Table
        tableHeader="TABS.SERVICES_ENABLED"
        columnDefs={SERVICES_ENABLED}
        rowData={servicesEnabled}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.SERVICES_ENABLED.ADD"
        limit={limit}
        total={servicesEnabledMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetServicesEnabled || !lastUpdatedGetServicesEnabled}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={SERVICES_ENABLED}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.SERVICES_ENABLED.SERVICE_ENABLED"
            editableRecord={editableRecord}
          >
            <ServiceEnabledForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingDeleteServiceEnabled || isPendingPostServiceEnabled || isPendingPutServiceEnabled
              }
              formValuesRef={formValuesRef}
              agreementsOptions={agreementsOptions}
              servicesOptions={filteredServicesOptions}
              timezonesOptions={timezonesOptions}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default ServicesEnabledScreen;
