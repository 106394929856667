import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { updateEntities } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getServices as getServicesApi,
  getServiceById as getServiceByIdApi,
  getScopeItems as getScopeItemsApi,
  postService as postServiceApi,
  postScope as postScopeApi,
  postScopeItem as postScopeItemApi,
  patchService as patchServiceApi,
  patchScope as patchScopeApi,
  patchScopeItem as patchScopeItemApi,
  deleteService as deleteServiceApi,
  deleteScope as deleteScopeApi,
  deleteScopeItem as deleteScopeItemApi,
  getResourceAttributes as getResourceAttributesApi,
  getBillingRules as getBillingRulesApi,
  getQuantityRules as getQuantityRulesApi,
  getAllServicesWithScopes as getAllServicesWithScopesApi,
  postMassChildrenEdit as postMassChildrenEditApi,
  patchBreakdownAttribute as patchBreakdownAttributeApi,
  postCloneService as postCloneServiceApi,
  getServiceProperties as getServicePropertiesApi,
  postRateImport as postRateImportApi,
  getRateImportFilePreview as getRateImportFilePreviewApi,
  patchRateImportSettings as patchRateImportSettingsApi,
  patchColumnSettings as patchColumnSettingsApi,
  deleteColumnSettings as deleteColumnSettingsApi,
  patchColumnMappings as patchColumnMappingsApi,
  postReorderTreeStructure as postReorderTreeStructureApi,
  patchTreeStructure as patchTreeStructureApi,
  deleteTreeStructure as deleteTreeStructureApi,
  postRateImportPreview as postRateImportPreviewApi,
  postApplyRateImport as postApplyRateImportApi,
  patchColumnProperties as patchColumnPropertiesApi,
} from 'queries/services';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetServices,
      lastUpdated: lastUpdatedGetServices,
    },
    getServices,
  ] = useMutation(getServicesApi);
  const [{ isPending: isPendingGetServiceById }, getServiceById] = useMutation(getServiceByIdApi);
  const [{ isPending: isPendingGetScopeItems }, getScopeItems] = useMutation(getScopeItemsApi);
  const [{ isPending: isPendingPostService }, postService] = useMutation(postServiceApi);
  const [{ isPending: isPendingPostScope }, postScope] = useMutation(postScopeApi);
  const [{ isPending: isPendingPostScopeItem }, postScopeItem] = useMutation(postScopeItemApi);
  const [{ isPending: isPendingPatchService }, patchService] = useMutation(patchServiceApi);
  const [{ isPending: isPendingPatchScope }, patchScope] = useMutation(patchScopeApi);
  const [{ isPending: isPendingPatchScopeItem }, patchScopeItem] = useMutation(patchScopeItemApi);
  const [{ isPending: isPendingDeleteService }, deleteService] = useMutation(deleteServiceApi);
  const [{ isPending: isPendingDeleteScope }, deleteScope] = useMutation(deleteScopeApi);
  const [{ isPending: isPendingDeleteScopeItem }, deleteScopeItem] = useMutation(deleteScopeItemApi);
  const [{ isPending: isPendingGetResourceAttributes }, getResourceAttributes] = useMutation(getResourceAttributesApi);
  const [{ isPending: isPendingGetBillingRules }, getBillingRules] = useMutation(getBillingRulesApi);
  const [{ isPending: isPendingGetQuantityRules }, getQuantityRules] = useMutation(getQuantityRulesApi);
  const [{ isPending: isPendingGetAllServicesWithScopes }, getAllServicesWithScopes] = useMutation(getAllServicesWithScopesApi);
  const [{ isPending: isPendingPostMassChildrenEdit }, postMassChildrenEdit] = useMutation(postMassChildrenEditApi);
  const [{ isPending: isPendingPatchBreakdownAttribute }, patchBreakdownAttribute] = useMutation(patchBreakdownAttributeApi);
  const [, postCloneService] = useMutation(postCloneServiceApi);
  const [{ isPending: isPendingGetServiceProperties }, getServiceProperties] = useMutation(getServicePropertiesApi);
  const [{ isPending: isPendingPostRateImport }, postRateImport] = useMutation(postRateImportApi);
  const [{ isPending: isPendingGetRateImportFilePreview }, getRateImportFilePreview] = useMutation(getRateImportFilePreviewApi);
  const [{ isPending: isPendingPatchRateImportSettings }, patchRateImportSettings] = useMutation(patchRateImportSettingsApi);
  const [{ isPending: isPendingPatchColumnSettings }, patchColumnSettings] = useMutation(patchColumnSettingsApi);
  const [{ isPending: isPendingDeleteColumnSettings }, deleteColumnSettings] = useMutation(deleteColumnSettingsApi);
  const [{ isPending: isPendingPatchColumnMappings }, patchColumnMappings] = useMutation(patchColumnMappingsApi);
  const [, postReorderTreeStructure] = useMutation(postReorderTreeStructureApi);
  const [{ isPending: isPendingPatchTreeStructure }, patchTreeStructure] = useMutation(patchTreeStructureApi);
  const [{ isPending: isPendingDeleteTreeStructure }, deleteTreeStructure] = useMutation(deleteTreeStructureApi);
  const [{ isPending: isPendingPostRateImportPreview }, postRateImportPreview] = useMutation(postRateImportPreviewApi);
  const [{
    isPending: isPendingPostApplyRateImport,
    lastUpdated: lastUpdatedPostApplyRateImport,
  }, postApplyRateImport] = useMutation(postApplyRateImportApi);
  const [{ isPending: isPendingPatchColumnProperties }, patchColumnProperties] = useMutation(patchColumnPropertiesApi);

  const selector = useCallback(state => ({
    servicesT: getArray(state, 'servicesT'),
    servicesTMeta: getObject(state, 'servicesTMeta'),
    resourceAttributes: getArray(state, 'resourceAttributes'),
    resourceAttributesDictionary: getObject(state, 'resourceAttributesDictionary'),
    billingRules: getArray(state, 'billingRules'),
    quantityRules: getArray(state, 'quantityRules'),
    billingRulesDictionary: getObject(state, 'billingRulesDictionary'),
    quantityRulesDictionary: getObject(state, 'quantityRulesDictionary'),
    rateImportInfo: getObject(state, 'rateImportInfo'),
    serviceProperties: getArray(state, 'serviceProperties'),
  }), []);
  const data = useISESelector(selector);

  const dispatch = useDispatch();

  const clearRateImportInfo = () => {
    dispatch(
      updateEntities({
        rateImportInfo: () => {},
      }),
    );
  };

  return {
    getServices,
    getServiceById,
    getScopeItems,
    postService,
    postScope,
    postScopeItem,
    patchService,
    patchScope,
    patchScopeItem,
    deleteService,
    deleteScope,
    deleteScopeItem,
    getResourceAttributes,
    getBillingRules,
    getQuantityRules,
    getAllServicesWithScopes,
    postMassChildrenEdit,
    patchBreakdownAttribute,
    postCloneService,
    getServiceProperties,
    postRateImport,
    getRateImportFilePreview,
    patchRateImportSettings,
    patchColumnSettings,
    deleteColumnSettings,
    patchColumnMappings,
    clearRateImportInfo,
    postReorderTreeStructure,
    patchTreeStructure,
    deleteTreeStructure,
    postRateImportPreview,
    postApplyRateImport,
    patchColumnProperties,
    isPendingGetServices,
    isPendingGetServiceById,
    isPendingGetScopeItems,
    isPendingPostService,
    isPendingPostScope,
    isPendingPostScopeItem,
    isPendingPatchService,
    isPendingPatchScope,
    isPendingPatchScopeItem,
    isPendingDeleteService,
    isPendingDeleteScope,
    isPendingDeleteScopeItem,
    isPendingGetResourceAttributes,
    isPendingGetBillingRules,
    isPendingGetQuantityRules,
    isPendingGetAllServicesWithScopes,
    isPendingPostMassChildrenEdit,
    isPendingPatchBreakdownAttribute,
    isPendingGetServiceProperties,
    isPendingPostRateImport,
    isPendingGetRateImportFilePreview,
    isPendingPatchRateImportSettings,
    isPendingPatchColumnSettings,
    isPendingDeleteColumnSettings,
    isPendingPatchColumnMappings,
    isPendingPatchTreeStructure,
    isPendingDeleteTreeStructure,
    isPendingPostRateImportPreview,
    isPendingPostApplyRateImport,
    isPendingPatchColumnProperties,
    lastUpdatedGetServices,
    lastUpdatedPostApplyRateImport,
    ...data,
  };
};
