import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';

import {
  CheckboxField,
  DatePickerField,
} from 'components/fields';
import { SCOPE_SCHEMA } from 'consts/validationSchemas';
import Button from '@setproduct-ui/core/Button';
import Chips from '@setproduct-ui/core/Chips';

import Dropdown from './Dropdown';
import './style.scss';

const ScopeItem = ({
  id,
  isDraft,
  effectiveFrom,
  effectiveTill,
  label,
  chipsProps,
  activeScope,
  onClick,
  isNewScope,
  currentServiceScopes,
  restServicesWithScopes,
  onSubmit,
  isPending,
  onDelete,
  setActiveScope,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [isExpand, setIsExpand] = useState(false);
  const [withCopyTree, setWithCopyTree] = useState(false);

  const scopeItemClass = cx('scope-item', {
    'scope-item_active': activeScope === id,
    'scope-item_expand': (activeScope === id && isNewScope) || isExpand,
    'scope-item_new-scope': isNewScope,
  });

  const onDeleteClick = () => {
    if (activeScope === id) {
      onDelete();
    }
  };
  const onCrossClick = () => {
    setIsExpand(false);
    formRef.current.resetForm();
    if (isNewScope) {
      setActiveScope(undefined);
    }
  };
  const onEditClick = () => {
    if (activeScope === id) {
      setIsExpand(true);
    }
  };
  const onDoubleClick = () => {
    if (activeScope === id) {
      setIsExpand(true);

      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }
  };
  const onWithCopyChange = (newVal) => {
    setWithCopyTree(newVal);

    if (!newVal) {
      formRef.current.setFieldValue('copyTreeFrom', undefined);
    }
  };
  const onSubmitModify = (values) => {
    onSubmit(
      values,
      () => {
        setIsExpand(false);
        formRef.current?.resetForm();
      },
    );
  };

  useEffect(() => {
    if (activeScope !== id && (isExpand || isNewScope)) {
      setIsExpand(false);
      setWithCopyTree(false);
      formRef?.current?.resetForm();
    }
  }, [activeScope]);

  return (
    <Formik
      initialValues={{
        id,
        effectiveFrom: effectiveFrom ? moment(effectiveFrom).toDate() : undefined,
        effectiveTill: effectiveTill ? moment(effectiveTill).toDate() : null,
        isDraft,
      }}
      onSubmit={onSubmitModify}
      validationSchema={SCOPE_SCHEMA}
      innerRef={formRef}
      enableReinitialize
      validateOnMount
    >
      {({
        handleSubmit, errors, setFieldTouched, values,
      }) => (
        <div
          className={scopeItemClass}
          role="presentation"
          onClick={onClick}
          onDoubleClick={onDoubleClick}
        >
          <div className="scope-item__read-only-info">
            {isNewScope ? (
              <div className="scope-item__plus-button">
                <Icon icon="plus" color="var(--blue70)" />
                {t('CONTROLS.SERVICES.NEW_SCOPE')}
              </div>
            ) : (
              <>
                <div className="scope-item__column">
                  <Chips
                    {...chipsProps}
                    type="dense"
                  />
                  <span className="scope-item__range">
                    {label}
                  </span>
                </div>
                <div className="scope-item__controls-wrapper">
                  <Button
                    icon={isExpand ? 'cross' : 'trash'}
                    color={isExpand ? 'default' : 'danger'}
                    onClick={isExpand ? onCrossClick : onDeleteClick}
                    view="outlined"
                    disabled={isPending}
                    dense
                  />
                  <Button
                    icon={isExpand ? 'tick' : 'edit'}
                    color="primary"
                    onClick={isExpand ? handleSubmit : onEditClick}
                    view={isExpand ? 'filled' : 'outlined'}
                    disabled={isPending}
                    dense
                  />
                </div>
              </>
            )}
          </div>
          <div className="scope-item__body">
            <Form className="scope-item__form-wrapper">
              <Field
                name="isDraft"
                text="SCREENS.SERVICES.DRAFT"
                component={CheckboxField}
                type="dense"
              />
              <Field
                name="effectiveFrom"
                label="INSTANCES.EFFECTIVE_FROM"
                component={DatePickerField}
                maxDate={values.effectiveTill}
                format="DD.MM.YYYY HH:mm:ss"
                timePrecision="second"
                isRequired={!values.isDraft}
                applyButton
                dense
                pickerDense
                usePortal
              />
              <Field
                name="effectiveTill"
                label="INSTANCES.EFFECTIVE_TILL"
                component={DatePickerField}
                minDate={values.effectiveFrom}
                format="DD.MM.YYYY HH:mm:ss"
                timePrecision="second"
                applyButton
                dense
                withIndefinitely
                pickerDense
                usePortal
              />
              {isNewScope && (
                <CheckboxField
                  value={withCopyTree}
                  onChange={onWithCopyChange}
                  text="SCREENS.SERVICES.COPY_RATE_TREE"
                  type="dense"
                />
              )}
              {withCopyTree && (
                <Field
                  name="copyTreeFrom"
                  component={Dropdown}
                  currentServiceScopes={currentServiceScopes}
                  restServicesWithScopes={restServicesWithScopes}
                />
              )}
              <div className="scope-item__form-buttons">
                <Button
                  text="CONTROLS.CANCEL"
                  icon="cross"
                  onClick={onCrossClick}
                  view="outlined"
                  disabled={isPending}
                  dense
                />
                <Button
                  text="CONTROLS.SAVE"
                  icon="tick"
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                    Object.keys(errors).map(i => setFieldTouched(i, true));
                  }}
                  disabled={isPending}
                  dense
                />
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ScopeItem;
