import classNamesBuilder from 'classnames';
import React from 'react';

import AgTable from 'components/AgTable';
import ScreenHeader from 'components/ScreenHeader';
import { Pagination } from 'components/Pagination';

import './style.scss';

export default ({
  limit = 1,
  total,
  page = 1,
  onChangeLimit,
  onChangePage,
  withPagination = true,
  tableHeader,
  buttonText,
  buttonView,
  openForm,
  openCustomColumns,
  classNames = {},
  showFilters,
  showColumns,
  sendQuery,
  isPending,
  iconColor,
  headerTabs,
  newDesign,
  headerDense,
  ...props
}) => {
  const paginationClass = classNamesBuilder('table-layout__pagination', {
    'table-layout__pagination_new-design': newDesign,
    [classNames.pagination]: classNames.pagination,
  });

  return (
    <div
      data-testid={`${window.location.pathname}/table${tableHeader ? `/${tableHeader.toLowerCase()?.replace('.', '-')}` : ''}`}
      className={`table-layout ${classNames.layout}`}
      key={tableHeader}
    >
      {(tableHeader || buttonText) && (
        <ScreenHeader
          title={tableHeader}
          openColumns={openCustomColumns}
          openForm={openForm}
          buttonText={buttonText}
          classNames={{
            screenHeader: classNames.screenHeader,
          }}
          showFilters={showFilters}
          showColumns={showColumns}
          buttonView={buttonView}
          iconColor={iconColor}
          dense={headerDense}
        />
      )}
      {headerTabs}
      <div className={`table-layout__container ${classNames.container}`}>
        <div className={`table-layout__wrapper ${classNames.table}`} key={tableHeader}>
          <AgTable
            {...props}
            sendQuery={sendQuery}
            key={tableHeader}
            loading={isPending}
          />
        </div>
        {
          withPagination && (
            <div className={paginationClass}>
              <Pagination
                total={total}
                limit={limit}
                page={page}
                onChangeLimit={onChangeLimit}
                onChangePage={onChangePage}
                onRefreshClick={sendQuery}
                isPending={isPending}
                tableHeader={tableHeader}
              />
            </div>
          )
        }
      </div>

    </div>
  );
};
