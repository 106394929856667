import { useHistory } from 'react-router-dom';
import React, {
  useEffect,
  useState,
} from 'react';

import { CustomColumnsView } from 'components/blocks';
import { ConfirmModal } from 'components/modals';
import Table from 'components/Table';
import Modal from 'components/Modal';
import ApplicationForm from 'components/forms/ApplicationForm';
import Spinner from '@setproduct-ui/core/Spinner';
import { APPLICATIONS } from 'consts/columns';
import { useScreen } from 'hooks';
import {
  useAgreementsApi,
  useSendersApi,
  useDictionariesApi,
  useUsersApi,
} from 'hooks/api';

import ApplicationModalTitle from './ApplicationModalTitle';

export default ({ screenRef }) => {
  // todo: Сделать поддержку сохранения экрана
  const spinnerWrapperStyle = {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
  };

  const history = useHistory();
  const { currentUser } = useUsersApi();
  const {
    getAgreements,
    agreementsAll,
  } = useAgreementsApi();
  const {
    getApplications,
    applications,
    applicationsMeta,
    getSenderById,
    postSender,
    isPendingPostSender,
    isPendingGetSenderById,
    putApplication,
    isPendingPutApplication,
    putSender,
    isPendingPutSender,
    isPendingGetApplications,
    lastUpdatedGetApplications,
  } = useSendersApi();
  const {
    openModal,
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
  } = useScreen({
    screenRef,
    getFunc: getApplications,
    deleteFunc: Function.prototype,
  });
  const [senderValues, setSenderValues] = useState();
  const [applicationValues, setApplicationValues] = useState();
  const {
    companiesOptions,
    getCompaniesDictionary,
    getSendersDictionary,
  } = useDictionariesApi();

  const onCloseModify = (isSave) => {
    onConfirmClose({ isSave });
    setApplicationValues(undefined);
    setSenderValues(undefined);
  };
  const onCloseViewSender = () => openModal('application');
  const onRowClicked = ({
    data: {
      senderProperties,
      channel,
      applicationType,
      senderIdId,
      senderId,
      status,
      id,
      createdAt,
      updatedAt,
      managerUser,
      companyId,
      partnerName,
    },
  }) => {
    if (channel === 2) {
      const properties = senderProperties;
      setApplicationValues({
        ...properties,
        status,
        id,
        applicationType,
        createdAt,
        updatedAt,
        managerUser: managerUser ? `${managerUser.firstName} ${managerUser.lastName}` : '',
        managerUserId: managerUser?.id,
        companyId,
        channel,
        partnerName,
        senderIdId,
        senderId,
      });
      const initialContactArrayValues = [{ value: null, label: null }];

      // NOTE необходимо для того, чтоб в случае, если в полях лежит null, в форме отобразились поля
      // с пустыми записями
      properties.phone = properties.phone || [...initialContactArrayValues];
      properties.website = properties.website || [...initialContactArrayValues];
      properties.email = properties.email || [...initialContactArrayValues];

      // Если это заявка на изменение, то подтягиваем информацию о сендере, чтоб показать что на что хотят поменять
      if (senderIdId) {
        getSenderById({
          id: senderIdId,
          successCallback: ({ vendorType, vendorConfig, ...res }) => {
            const parsedSenderProperties = res.properties;
            const parsedVendorConfig = vendorConfig;

            // NOTE: необходимо для того, чтоб в случае, если в полях лежит null, в форме отобразились поля
            // с пустыми записями
            parsedSenderProperties.phone = parsedSenderProperties.phone || [...initialContactArrayValues];
            parsedSenderProperties.website = parsedSenderProperties.website || [...initialContactArrayValues];
            parsedSenderProperties.email = parsedSenderProperties.email || [...initialContactArrayValues];

            if (vendorType === 'alaris_gsma') {
              setSenderValues({
                ...parsedSenderProperties,
                agreementId: res.agreementId,
                senderStatus: res.status,
                webHookGuid: res.webHookGuid,
                senderId: res.senderId,
                companyId: res.companyId,
                senderIdId,
                vendorType,
                ...parsedVendorConfig,
              });
            } else {
              setSenderValues({
                ...parsedSenderProperties,
                agreementId: res.agreementId,
                senderStatus: res.status,
                webHookGuid: res.webHookGuid,
                senderId: res.senderId,
                companyId: res.companyId,
                senderIdId,
                vendorType,
                vendorConfig: !parsedVendorConfig ? undefined : { ...parsedVendorConfig },
              });
            }
          },
        });
      }

      openModal('application');
    }
  };
  const onApplicationSubmit = (body) => {
    const managerUserId = currentUser?.id;
    if (body.status === 1 && applicationValues.applicationType === 1 && body.senderIdId) {
      setApplicationValues({
        ...applicationValues,
        ...senderValues,
      });

      openModal('sender');
      return;
    }
    if (body.status === 1) {
      const defaultApplicationProps = {
        ...senderValues,
        senderIdId: senderValues?.senderIdId,
        vendorType: senderValues?.vendorType,
        senderStatus: senderValues?.senderStatus || 0,
        agreementId: senderValues?.agreementId,
        webHookGuid: senderValues?.webHookGuid,
        senderId: senderValues?.senderId,
      };

      setApplicationValues(prev => ({
        ...defaultApplicationProps,
        ...prev,
      }));

      openModal('sender');
      return;
    }
    putApplication({
      body: {
        id: body.id,
        status: 1,
        managerUserId,
      },
      successCallback: ({ managerUser }) => {
        sendQuery();
        setApplicationValues(prev => ({
          ...prev,
          status: 1,
          managerUserId,
          managerUser: `${managerUser.firstName} ${managerUser.lastName}`,
        }));
      },
    });
  };
  const onSenderSubmit = ({
    id, senderIdId, senderStatus, ...body
  }) => {
    (senderIdId ? putSender : postSender)({
      body: {
        id: senderIdId,
        applicationId: id,
        ...body,
        status: senderStatus,
      },
      successCallback: () => {
        sendQuery();
        onCloseModify(true);
      },
    });
  };
  const onApplicationReject = () => {
    putApplication({
      body: {
        id: applicationValues.id,
        status: -1,
        managerUserId: currentUser?.id,
      },
      successCallback: () => {
        sendQuery();
        onCloseModify(true);
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
    getSendersDictionary();
    // todo времено, лучше сделать отдельный запрос на получение всех записей
    getAgreements({
      storeKey: 'agreementsAll',
      limit: 1000,
    });

    if (history.location?.state?.ratePlanId) {
      tableRef.current.setFilters({
        ratePlanId: {
          type: 'list',
          values: {
            [history.location?.state?.ratePlanId]: true,
          },
        },
      });
    }
  }, []);

  return (
    <div>
      <Table
        limit={limit}
        total={applicationsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.APPLICATIONS"
        openCustomColumns={openCustomViewDrawer}
        columnDefs={APPLICATIONS}
        isPending={isPendingGetApplications || !lastUpdatedGetApplications}
        rowData={applications}
        onRowClicked={onRowClicked}
        tableRef={tableRef}
        sendQuery={sendQuery}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={APPLICATIONS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
             isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
           }
        />
      )}
      {
        (isOpenModal === 'application' || isOpenModal === 'viewSender') && (
          <Modal
            closeModal={onCloseModify}
            titleComponent={(
              <ApplicationModalTitle
                applicationType={applicationValues?.applicationType}
                submitted={applicationValues?.createdAt}
                updated={applicationValues?.updatedAt}
                managerUser={applicationValues?.managerUser}
              />
            )}
            styles={{
              titleFont: {
                width: '100%',
              },
            }}
            size="lx"
          >
            {
              isPendingGetSenderById ? (
                <div style={spinnerWrapperStyle}>
                  <Spinner />
                </div>
              ) : (
                <ApplicationForm
                  partnersOptions={companiesOptions}
                  agreements={agreementsAll}
                  initialValues={applicationValues}
                  senderValues={applicationValues.applicationType === 2 ? senderValues : null}
                  leftButtonText={applicationValues.status === -1 ? '' : 'CONTROLS.APPLICATIONS.REJECT'}
                  onLeftButtonClick={onApplicationReject}
                  rightButtonText={
                    applicationValues.status !== 1
                      ? 'CONTROLS.SENDER_IDS.TAKE_TO_WORK'
                      : 'CONTROLS.SENDER_IDS.GO_TO_SENDER'
                  }
                  onSenderClick={() => openModal('viewSender')}
                  isPending={isPendingPutApplication}
                  onRightButtonClick={onApplicationSubmit}
                  showControls={applicationValues.status !== -2 && applicationValues.status !== 2}
                  onCancel={onCloseModify}
                  currentUserId={currentUser?.id}
                  showHeader
                  readOnly
                />
              )
            }
          </Modal>
        )
      }
      {
        isOpenModal === 'sender' && (
          <Modal
            mode={applicationValues?.applicationType === 2 ? 'edit' : 'add'}
            closeModal={onCloseModify}
            title="SCREENS.SENDER_IDS.SENDER"
            size="lx"
          >
            {
              isPendingGetSenderById ? (
                <div style={spinnerWrapperStyle}>
                  <Spinner />
                </div>
              ) : (
                <ApplicationForm
                  partnersOptions={companiesOptions}
                  agreements={agreementsAll}
                  initialValues={applicationValues}
                  senderValues={senderValues}
                  rightButtonText="CONTROLS.SAVE"
                  isPending={isPendingPostSender || isPendingPutSender}
                  onRightButtonClick={onSenderSubmit}
                  onCancel={onCloseModify}
                  highlightChanged
                />
              )
            }
          </Modal>
        )
      }
      {
        isOpenModal === 'viewSender' && (
          <Modal
            mode="view"
            closeModal={onCloseViewSender}
            title="SCREENS.SENDER_IDS.SENDER"
            backdropOpacity={0}
            size="lx"
          >
            {
              isPendingGetSenderById ? (
                <div style={spinnerWrapperStyle}>
                  <Spinner />
                </div>
              ) : (
                <ApplicationForm
                  partnersOptions={companiesOptions}
                  agreements={agreementsAll}
                  initialValues={senderValues}
                  onCancel={onCloseViewSender}
                  showControls={false}
                  showTabs
                  readOnly
                />
              )
            }
          </Modal>
        )
      }
    </div>
  );
};
