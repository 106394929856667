import { Field } from 'formik';
import { useToggle } from 'react-use';
import React from 'react';
import { Collapse, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { MinusIcon, PlusIcon } from 'assets/icons';
import {
  TextField,
  DropdownField,
  SwitchField,
  CodeField,
  TextAreaField,
} from 'components/fields';
import { Typography, Modal } from 'components';
import Chips from '@setproduct-ui/core/Chips';
import { REPORTS_TYPES } from 'consts';
import { useModalLogic } from 'hooks';

import './style.scss';

export default ({
  id,
  name,
  typeID,
  onDelete,
  onClone,
  sqlQuery,
  fieldName,
  fieldIndex,
  errors,
  touched,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useToggle(true);
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');

  const headerStyle = classNames('setting-field__header', {
    'setting-field__header_expanded': isOpen,
  });
  const getFieldClassname = (propName, propType = 'text-field') =>
    classNames(`setting-field__${propType}`, {
      [`setting-field__${propType}_with-error`]: touched && errors?.[propName],
    });
  const onDeleteHandler = () => {
    onDelete(id);
  };
  const onCloneHandler = () => {
    onClone(id);
  };

  return (
    <div className="setting-field">
      <div
        className={headerStyle}
        role="presentation"
      >
        {isOpen ? (
          <MinusIcon className="setting-field__header-icon" onClick={setIsOpen} />
        ) : (
          <PlusIcon className="setting-field__header-icon" onClick={setIsOpen} />
        )}
        {name}
        <div className="setting-field__header-controls">
          <Icon
            icon="git-branch"
            color="var(--blue90)"
            size={10}
            onClick={onCloneHandler}
          />
          <Icon
            icon="small-cross"
            color="var(--blue90)"
            size={12}
            onClick={onDeleteHandler}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="setting-field__body">
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              PLACEHOLDERS.REPORTS.PARAMETER_TYPE
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].typeID`}
                component={DropdownField}
                options={REPORTS_TYPES}
                dense
                isRequired
                fieldError={errors?.typeID}
                fieldTouched={touched}
                showErrorMessage={false}
                placeholder="PLACEHOLDERS.REPORTS.SELECT_PARAMETER_TYPE"
                fieldClassNames={{
                  container: getFieldClassname('typeID', 'dropdown-field'),
                }}
              />
            </div>
          </div>
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              SCREENS.REPORTS.IS_REQUIRED
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].mandatory`}
                component={SwitchField}
                label={t('CONTROLS.REPORTS.YES')}
                placeholder="SCREENS.REPORTS.IS_REQUIRED"
                type="dense"
                color="primary"
                dense
                classNames={{
                  container: 'setting-field__switch-field',
                }}
              />
            </div>
          </div>
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              INSTANCES.NAME
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].name`}
                fieldError={errors?.name}
                fieldTouched={touched}
                component={TextField}
                showErrorMessage={false}
                className={getFieldClassname('name')}
                dense
              />
            </div>
          </div>
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              SCREENS.REPORTS.BIND_NAME
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].bindName`}
                fieldError={errors?.bindName}
                fieldTouched={touched}
                component={TextField}
                showErrorMessage={false}
                className={getFieldClassname('bindName')}
                dense
              />
            </div>
          </div>
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              SCREENS.REPORTS.DEFAULT
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].defaultExp`}
                component={TextField}
                className="setting-field__text-field"
                dense
              />
            </div>
          </div>
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              SCREENS.REPORTS.DESCRIPTION
            </Typography>
            <div className="setting-field__field-control">
              <Field
                name={`${fieldName}.[${fieldIndex}].description`}
                component={TextAreaField}
                classNames={{
                  container: 'setting-field__text-area-field',
                }}
                cacheMeasurements
                spellCheck={false}
              />
            </div>
          </div>
          {isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={closeModal}
            title="SCREENS.REPORTS.SQL_QUERY"
          >
            <div className="setting-field__code-modal">
              <Field
                name={`${fieldName}.[${fieldIndex}].sqlQuery`}
                component={CodeField}
                onCancel={closeModal}
                theme="tomorrow"
                mode="mysql"
                classNames={{
                  input: 'setting-field__code-field-input',
                }}
              />
            </div>
          </Modal>
          )}
          {(typeID === 5 || typeID === 4) && (
          <div className="setting-field__field">
            <Typography className="setting-field__field-label">
              SCREENS.REPORTS.DATA_SQL_QUERY
            </Typography>
            <div className="setting-field__field-control">
              <div
                role="presentation"
                onClick={() => openModal('edit')}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Chips
                  round={false}
                  type="dense"
                  color="primary"
                  tag={sqlQuery ? t('CONTROLS.REPORTS.VIEW_EDIT') : t('CONTROLS.REPORTS.ADD')}
                  style={{
                    marginLeft: 4,
                  }}
                />
              </div>
            </div>
          </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};
