import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';

import Color from '@setproduct-ui/styles/color.module.css';
import { useServicesApi } from 'hooks/api';

import ListItem from './ListItem';
import styles from './MultiAddDrawer.module.scss';

import Checkbox from '../Checkbox';
import Drawer from '../Drawer';
import { ReactComponent as SearchIcon } from '../../icons/magnifying-glass.svg';

const MultiAddDrawer = ({
  isOpen,
  toggleOpen,
  resourceAttributesDictionary,
  data,
  resourceCellOptions,
  resourcesOptions,
  definitionsList,
  definitionsFiltersForChildren,
  getFilteredItems,
  getNodesListAndMakeTree,
}) => {
  if (!isOpen) {
    return null;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const {
    postMassChildrenEdit,
    isPendingPostMassChildrenEdit,
  } = useServicesApi();
  const [options, setOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [listToDelete, setListToDelete] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isMassChecked, setIsMassChecked] = useState(false);

  const title = useMemo(() => {
    const matchAttributeValueLabel = resourceCellOptions
      .find(option => String(option.value) === String(data.matchAttributeValues[0]))?.label
        || data.matchAttributeValues[0];

    if (data.level === 1) {
      return {
        attribute: 'Service domain',
        attributeValue: matchAttributeValueLabel,
        childrenAttribute: 'Resource',
      };
    }
    if (data.level === 2) {
      return {
        attribute: 'Resource',
        attributeValue: matchAttributeValueLabel,
        childrenAttribute: resourceAttributesDictionary[data.childrenMatchAttributeId]?.displayName,
      };
    }

    return {
      attribute: resourceAttributesDictionary[data.matchAttributeId]?.displayName,
      attributeValue: matchAttributeValueLabel,
      childrenAttribute: resourceAttributesDictionary[data.childrenMatchAttributeId]?.displayName,
    };
  }, [data.matchAttributeId, data.childrenMatchAttributeId]);
  const deletedSubItemsCount = useMemo(() => listToDelete.reduce((acc, item) => {
    const optionToDelete = options.find(option => option.scopeItemId === item);

    if (optionToDelete?.childrenLength) {
      acc += optionToDelete.childrenLength;
    }

    return acc;
  }, 0), [listToDelete, options]);
  const displayOptions = useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()));
  }, [searchValue, options]);

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value);
  };
  const onMassCheckChange = (checked) => {
    setIsMassChecked(checked);

    if (checked) {
      setCheckedList(prev => options.reduce((acc, option) => {
        const foundCheckedOption = prev.find(item => item.value === option.value);

        if (foundCheckedOption) {
          acc.push({ ...foundCheckedOption });
        } else {
          acc.push({ value: option.value });
        }

        return acc;
      }, []));
    } else {
      setCheckedList(prev => prev.filter(item => item.existed));
    }
  };
  const onSave = () => {
    postMassChildrenEdit({
      scopeItemId: data.id,
      body: {
        matchAttributeValuesToAdd: checkedList.reduce((acc, item) => {
          if (!listToDelete.includes(item.scopeItemId) && !item.existed) {
            acc.push(String(item.value));
          }
          return acc;
        }, []),
        serviceScopeItemIDsToDelete: listToDelete,
      },
      successCallback: getNodesListAndMakeTree,
    });
  };

  useEffect(() => {
    if (data.level === 1) {
      setOptions(resourcesOptions.reduce((acc, resource) => {
        if (resource.serviceDomainId === data.matchAttributeValues[0]) {
          const existingNode = data?.children
            ?.find(children => String(children.matchAttributeValues?.[0]) === String(resource.value));

          if (existingNode) {
            setCheckedList(prev => [...prev, { value: resource.value, existed: true }]);
          }
          acc.push({
            ...resource,
            disabled: !!existingNode,
            childrenLength: existingNode?.children?.length,
            scopeItemId: existingNode?.id,
          });

          return acc;
        }
        return acc;
      }, []));
      return;
    }

    const dictionaryIdForChildren = resourceAttributesDictionary?.[data.childrenMatchAttributeId]?.dictionaryId;

    if (data.level === 2 && dictionaryIdForChildren) {
      setOptions([
        ...definitionsList[dictionaryIdForChildren],
        {
          label: 'Others',
          value: 'null',
        },
      ].map((item) => {
        const existingNode = data?.children
          ?.find(children => (String(children.matchAttributeValues?.[0]) === String(item.value)
            || children.matchAttributeValues?.length === 0 && item.value === 'null'));

        if (existingNode) {
          setCheckedList(prev => [...prev, { value: item.value, existed: true }]);
        }

        return {
          ...item,
          disabled: !!existingNode,
          childrenLength: existingNode?.children?.length,
          scopeItemId: existingNode?.id,
        };
      }));
      return;
    }

    if (dictionaryIdForChildren) {
      getFilteredItems({
        dictionaryId: dictionaryIdForChildren,
        body: definitionsFiltersForChildren,
        successCallback: (res) => {
          setOptions([
            ...(definitionsList[dictionaryIdForChildren] || []),
            {
              label: 'Others',
              value: 'null',
            },
          ].reduce((acc, item) => {
            if (res?.data?.some(key => key === item.value) || item.value === 'null') {
              const existingNode = data?.children
                ?.find(children => (String(children.matchAttributeValues?.[0]) === String(item.value)
                  || children.matchAttributeValues?.length === 0 && item.value === 'null'));

              if (existingNode) {
                setCheckedList(prev => [...prev, { value: item.value, existed: true }]);
              }

              acc.push({
                ...item,
                disabled: !!existingNode,
                childrenLength: existingNode?.children?.length,
                scopeItemId: existingNode?.id,
              });
            }
            return acc;
          }, []));
        },
      });
    }
  }, [data.level, data.dictionaryId]);

  return (
    <Drawer
      closeDrawer={toggleOpen}
      title={(
        <span>
          {`${title.attribute}: `}
          <span style={{ color: 'var(--grey100)' }}>{title.attributeValue}</span>
          {` - by ${title.childrenAttribute}`}
        </span>
      )}
      onSave={onSave}
      isPending={isPendingPostMassChildrenEdit}
    >
      <div className={styles.card}>
        <div className={styles.searchRow}>
          <Checkbox
            checked={isMassChecked}
            onChange={onMassCheckChange}
          />
          <input
            type="text"
            value={searchValue}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <SearchIcon />
        </div>
        <div className={styles.list}>
          {displayOptions.map(option => (
            <ListItem
              key={option.value}
              checked={checkedList.some(item => item.value === option.value)}
              isToDelete={listToDelete.includes(option.scopeItemId)}
              setCheckedList={setCheckedList}
              setListToDelete={setListToDelete}
              {...option}
            />
          ))}
        </div>
      </div>
      <span className={cx(styles.changesInfo, Color.danger)}>
        {`${listToDelete.length} item with all its ${deletedSubItemsCount} sub-items will be deleted`}
      </span>
      <span className={cx(styles.changesInfo, Color.primary)}>
        {`${checkedList.filter(item => !item.existed).length} new items will be added`}
      </span>
    </Drawer>
  );
};

export default MultiAddDrawer;
