import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
  useImperativeHandle,
} from 'react';
import { cloneDeep } from 'lodash';
import cx from 'classnames';

import Spinner from '@setproduct-ui/core/Spinner';
import { useServicesApi } from 'hooks/api';

import Row from './Row';
import AddButtonRow from './AddButtonRow';
import styles from './Table.module.scss';

const initialZIndex = 10000;

const Table = memo(({
  scopeId,
  activeFormRefLink,
  activeRowRef,
  tableRef,
  highlightActiveRow,
  currency,
  serviceType,
  externalData,
  initialLevel,
  readOnly,
  className,
  imperativeRef,
  parentInfoRules,
}) => {
  const tableClass = cx(styles.table, {
    [styles.table_unitPackService]: serviceType === 2,
    [styles.table_monetaryPackService]: serviceType === 3,
    [styles.table_readOnly]: readOnly,
    [styles.table_readOnlyUnitPackService]: readOnly && serviceType === 2,
    [styles.table_readOnlyMonetaryPackService]: readOnly && serviceType === 3,
    [className]: className,
  });

  const lastZIndex = useRef(initialZIndex);
  const totalItemsCount = useRef(0);
  const usedDomains = useRef([]);
  const {
    resourceAttributesDictionary,
    getScopeItems,
    postScopeItem,
    patchScopeItem,
    deleteScopeItem,
    isPendingPostScopeItem,
    isPendingPatchScopeItem,
    isPendingDeleteScopeItem,
    isPendingGetScopeItems,
  } = useServicesApi();
  const [groupedRows, setGroupedRows] = useState([]);
  const [activeRow, setActiveRow] = useState('');
  const [selectedRow, setSelectedRow] = useState('');

  const priceTypeColumnName = useMemo(() => {
    if (serviceType === 1) {
      return 'Price type';
    }
    if (serviceType === 2 || serviceType === 3) {
      return 'Status';
    }

    return 'Unknown service type';
  }, [currency, serviceType]);

  const updateItem = (itemId, updatedFields) => {
    setGroupedRows((prev) => {
      const updatedData = [...prev];

      const updateItemRecursive = (data) => {
        let found = false;
        data.forEach((item) => {
          if (item.id === itemId) {
            Object.assign(item, updatedFields);
            found = true;
          } else if (!found && item.children) {
            found = updateItemRecursive(item.children);
          }
        });
        return found;
      };

      if (updateItemRecursive(updatedData)) {
        return updatedData;
      }
      return prev;
    });
  };
  const getDebugName = (level, index, parentName) => {
    if (level === 1) {
      return `root${index + 1}`;
    }
    return `${parentName}.child${index + 1}`;
  };
  const addChild = (parent) => {
    setGroupedRows((prev) => {
      const updatedData = [...prev];
      const newChild = {
        id: 'newRow',
        billingRule: null,
        childrenMatchAttributeId: null,
        matchAttributeId: parent.childrenMatchAttributeId,
        matchAttributeValues: null,
        parentId: parent.id,
        price: null,
        priceType: null,
        quantityRule: null,
        scaleCounterGroupBy: null,
        scaleType: null,
        scopeId,
        level: parent.level + 1,
        dictionaryId: parent.level + 1 > 2
          ? resourceAttributesDictionary?.[parent.childrenMatchAttributeId]?.dictionaryId
          : undefined,
        debugName: getDebugName(parent.level + 1, parent.children?.length || 1, parent.debugName),
      };

      const addChildRecursive = (data) => {
        let found = false;
        data.forEach((item) => {
          if (item.id === parent.id) {
            if (item.children) {
              item.children.push(newChild);
            } else {
              item.children = [newChild];
            }
            found = true;
          } else if (!found && item.children) {
            found = addChildRecursive(item.children);
          }
        });
        return found;
      };

      if (addChildRecursive(updatedData)) {
        totalItemsCount.current += 1;
        setActiveRow('newRow');
        setSelectedRow('newRow');
        return updatedData;
      }
      return prev;
    });
  };
  const removeItemById = (data, idToRemove) => data.filter((item) => {
    if (item.id === idToRemove) {
      if (item.level === 1) {
        usedDomains.current = usedDomains.current?.filter(domain => String(domain) !== String(item.matchAttributeValues?.[0]));
      }

      return false;
    } if (Array.isArray(item.children)) {
      item.children = removeItemById(item.children, idToRemove);
    }
    return true;
  });
  const onSaveRow = ({
    id,
    matchAttributeValues,
    debugName,
    priceTypeCell,
    level,
    price,
    ...values
  }, successCallback) => {
    (id === 'newRow' ? postScopeItem : patchScopeItem)({
      body: {
        id,
        matchAttributeValues: !matchAttributeValues || matchAttributeValues === 'null'
          ? []
          // todo: нужно чтобы значения колонки resource всех уровней были строками
          : [level > 2 ? `${matchAttributeValues}` : matchAttributeValues],
        price: priceTypeCell === '2/price'
          ? price?.map?.(item => ({
            price: +item.price,
            tier: item.tier === null ? null : +item.tier,
          }))
          : price ? +price : price,
        ...values,
      },
      successCallback: ({ data }) => {
        setActiveRow('');
        setSelectedRow('');
        updateItem(id, data);
        successCallback?.();
      },
    });
    if (level === 1) {
      usedDomains.current = groupedRows.reduce((acc, item) => {
        if (item.id !== id) {
          acc.push(item.matchAttributeValues?.[0]);
        } else {
          acc.push(matchAttributeValues);
        }
        return acc;
      }, []);
    }
  };
  const onAddParent = () => {
    if (!activeRow) {
      setActiveRow('newRow');
      setSelectedRow('newRow');
      totalItemsCount.current += 1;
      setGroupedRows(prev => [
        ...prev,
        {
          id: 'newRow',
          billingRule: null,
          childrenMatchAttributeId: 4,
          matchAttributeId: 3,
          matchAttributeValues: null,
          parentId: null,
          price: null,
          priceType: null,
          quantityRule: null,
          scaleCounterGroupBy: null,
          scaleType: null,
          scopeId,
          level: 1,
          debugName: getDebugName(1, prev.length),
        },
      ]);
    }
  };
  const onDeleteRow = (id) => {
    if (id === 'newRow') {
      setActiveRow('');
      setSelectedRow('');
      totalItemsCount.current -= 1;
      setGroupedRows(prev => removeItemById(prev, id));
    } else {
      deleteScopeItem({
        id,
        successCallback: () => {
          setActiveRow('');
          setSelectedRow('');
          totalItemsCount.current -= 1;
          setGroupedRows(prev => removeItemById(prev, id));
        },
      });
    }
  };
  const onChangeActiveRow = (rowId, formRef, rowRef) => {
    if (rowId) {
      if (activeRow && activeRow !== rowId && activeFormRefLink?.current?.current?.dirty) {
        highlightActiveRow();
        return;
      }
      if (activeRow === 'newRow') {
        onDeleteRow(activeRow);
      }
      activeRowRef.current = rowRef.current;
      activeFormRefLink.current = formRef;
    } else {
      activeFormRefLink.current = null;
      activeRowRef.current = null;
    }
    setActiveRow(rowId);
    setSelectedRow(rowId);
  };
  const updateActiveFormRefLink = (formRef) => {
    if (activeFormRefLink?.current) {
      activeFormRefLink.current = formRef;
    }
  };
  const onChangeSelectedRow = (rowId) => {
    if (activeRow && activeRow !== rowId && activeFormRefLink?.current?.current?.dirty) {
      highlightActiveRow();
      return;
    }

    if (activeRow === 'newRow') {
      onDeleteRow(activeRow);
    }
    setActiveRow('');
    setSelectedRow(rowId);
  };
  const getNodesListAndMakeTree = () => {
    if (externalData) {
      const clonedData = cloneDeep(externalData);
      const result = [];
      const childrenById = {};

      clonedData.forEach((item) => {
        if (item.parentId === null) {
          result.push(item);
        } else {
          if (!childrenById[item.parentId]) {
            childrenById[item.parentId] = [];
          }
          childrenById[item.parentId].push(item);
        }
      });

      const addChildren = (items, level, parentDebugName) => {
        items.forEach((item, index) => {
          item.level = level;
          item.debugName = getDebugName(level, index, parentDebugName);

          if (level > 2) {
            item.dictionaryId = resourceAttributesDictionary?.[item.matchAttributeId]?.dictionaryId;
          }

          if (childrenById[item.id]) {
            item.children = childrenById[item.id];
            addChildren(item.children, level + 1, item.debugName);
          }
        });
      };

      addChildren(result, initialLevel);
      usedDomains.current = result.map(item => item.matchAttributeValues?.[0]);

      totalItemsCount.current = externalData.length;
      setGroupedRows(result);
    } else if (scopeId) {
      getScopeItems({
        serviceScopeId: scopeId,
        successCallback: ({ data }) => {
          if (data.length) {
            const clonedData = cloneDeep(data);
            const result = [];
            const childrenById = {};

            clonedData.forEach((item) => {
              if (item.parentId === null) {
                result.push(item);
              } else {
                if (!childrenById[item.parentId]) {
                  childrenById[item.parentId] = [];
                }
                childrenById[item.parentId].push(item);
              }
            });

            const addChildren = (items, level, parentDebugName) => {
              items.forEach((item, index) => {
                item.level = level;
                item.debugName = getDebugName(level, index, parentDebugName);

                if (level > 2) {
                  item.dictionaryId = resourceAttributesDictionary?.[item.matchAttributeId]?.dictionaryId;
                }

                if (childrenById[item.id]) {
                  item.children = childrenById[item.id];
                  addChildren(item.children, level + 1, item.debugName);
                }
              });
            };

            addChildren(result, 1);
            usedDomains.current = result.map(item => item.matchAttributeValues?.[0]);

            totalItemsCount.current = data.length;
            setGroupedRows(result);
          } else {
            setGroupedRows([]);
          }
          setSelectedRow('');
          setActiveRow('');
          activeFormRefLink.current = null;
          activeRowRef.current = null;
        },
      });
    }
  };

  useImperativeHandle(imperativeRef, () => ({
    redrawTree() {
      getNodesListAndMakeTree();
    },
  }));

  useEffect(() => {
    getNodesListAndMakeTree();
  }, [scopeId, externalData]);
  useEffect(() => {
    lastZIndex.current = initialZIndex;
  }, [totalItemsCount.current]);

  if (isPendingGetScopeItems) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <div className={tableClass} ref={tableRef}>
      <div
        className={cx(styles.header, { [styles.header_readOnly]: readOnly })}
        style={{ '--header-z-index': initialZIndex }}
      >
        <div />
        <div />
        {!readOnly && (
          <div />
        )}
        <div>Resources</div>
        <div>{priceTypeColumnName}</div>
        {serviceType === 1 && (<div>{`Price, ${currency}`}</div>)}
        {serviceType !== 3 && (
        <>
          <div>Billing rule</div>
          <div>Quantity rule</div>
        </>
        )}
        {!readOnly && (
          <div />
        )}
      </div>
      {groupedRows.map(row => (
        <Row
          key={row.id}
          data={row}
          onDeleteRow={onDeleteRow}
          addChild={addChild}
          isPending={isPendingPostScopeItem || isPendingDeleteScopeItem || isPendingPatchScopeItem}
          onSubmit={onSaveRow}
          activeRow={activeRow}
          onChangeActiveRow={onChangeActiveRow}
          updateActiveFormRefLink={updateActiveFormRefLink}
          zIndex={initialZIndex}
          lastZIndex={lastZIndex}
          totalItemsCount={totalItemsCount.current}
          isPendingDelete={isPendingDeleteScopeItem}
          isPendingEdit={isPendingPatchScopeItem || isPendingPostScopeItem}
          selectedRow={selectedRow}
          setSelectedRow={onChangeSelectedRow}
          usedDomains={usedDomains.current?.filter(item => String(item) !== String(row.matchAttributeValues?.[0]))}
          serviceType={serviceType}
          serviceCurrency={currency}
          getNodesListAndMakeTree={getNodesListAndMakeTree}
          readOnly={readOnly}
          parentInfoRules={parentInfoRules}
        />
      ))}
      {!readOnly && (
        <AddButtonRow
          onClick={onAddParent}
          activeRow={activeRow}
        />
      )}
    </div>
  );
});

export default Table;
