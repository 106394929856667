import React from 'react';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import moment from 'moment';

import Button from '@setproduct-ui/core/Button';

const JSONDownloadCellRenderer = ({
  valueFormatted,
}) => {
  const downloadJSON = () => {
    const dateTime = moment().format('DDMMYYYYhhmmss');
    const jsonString = JSON.stringify(valueFormatted);
    const blob = new Blob([jsonString], { type: 'application/json' });
    saveAs(blob, `details${dateTime}.json`);
  };

  return !isEmpty(valueFormatted) && (
    <Button
      dense
      view="flat"
      color="primary"
      icon="import"
      text="INSTANCES.AUDIT.EXPORT"
      onClick={downloadJSON}
    />
  );
};

export default JSONDownloadCellRenderer;
