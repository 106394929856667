import React, { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';
import { useWindowSize } from 'react-use';

import './styles.scss';

export default ({ buttons, data }) => {
  const { width } = useWindowSize();

  const iconSize = useMemo(() => (width <= 1366 ? 12 : 16), [width]);

  return (
    <div className="buttons-cell-renderer">
      {buttons
      && buttons.map(button => (
        <Icon
          key={button.id}
          size={iconSize}
          {...button}
          onClick={(e) => {
            e.stopPropagation();
            button.onClick(data);
          }}
          className="buttons-cell-renderer__button"
        />
      ))}
    </div>
  );
};
