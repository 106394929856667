import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  memo,
  useCallback,
} from 'react';
import { Formik, Form } from 'formik';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { useToggle, usePrevious, useKey } from 'react-use';
import { cloneDeep, sortBy } from 'lodash';

import { TREE_ROW_SCHEMA } from 'consts/validationSchemas';
import { useModalLogic } from 'hooks';
import { useDictionariesApi, useServicesApi } from 'hooks/api';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { ConfirmModal } from 'components/modals';
import Spinner from '@setproduct-ui/core/Spinner';
import Color from '@setproduct-ui/styles/color.module.css';

import ResourceCell from './ResourceCell';
import PriceTypeCell from './PriceTypeCell';
import PriceCell from './PriceCell';
import RuleCell from './RuleCell';
import MultiAddDrawer from './MultiAddDrawer';
import BreakdownDrawer from './BreakdownDrawer';
import styles from './Row.module.scss';

import { ReactComponent as UndoIcon } from '../icons/arrow-rotate-left.svg';
import { ReactComponent as FloppyDiskIcon } from '../icons/floppy-disk.svg';
import { ReactComponent as EditIcon } from '../icons/pen-to-square.svg';
import { ReactComponent as DeleteIcon } from '../icons/trash-xmark.svg';
import { ReactComponent as TriangleIcon } from '../icons/triangle-exclamaition.svg';

// const arePropsEqual = (prevProps, nextProps) => {
//   if (prevProps.selectedRow !== nextProps.selectedRow) {
//     console.log('NEXT PROPS ', nextProps);
//     if (nextProps.selectedRow === nextProps.data.debugName) {
//       console.log(`DEBUG NAME: ${nextProps.data.debugName} WILL RERENDER`);
//       return false;
//     }
//     if (prevProps.selectedRow === prevProps.data.debugName) {
//       console.log(`DEBUG NAME: ${nextProps.data.debugName} WILL RERENDER`);
//       return false;
//     }
//     if (nextProps.data.children?.length) {
//       // USE DEBUG NAME FOR DETECT PARENT-CHILD RELATIONS
//       const test = !nextProps.data.children.some(child => nextProps.selectedRow.includes(child.debugName));
//       if (!test) {
//         console.log(`DEBUG NAME: ${nextProps.data.debugName} WILL RERENDER`);
//       }
//       return test;
//     }
//     // nextProps.data.children?.forEach((child) => {
//     //   if (child.id === nextProps.selectedRow) {
//     //     isPropsEqual = false;
//     //   }
//     //   if (child.id === prevProps.selectedRow) {
//     //     isPropsEqual = false;
//     //   }
//     //   isPropsEqual = true;
//     // });
//   }
//
//   console.log(`DEBUG NAME: ${nextProps.data.debugName} WILL NOT RERENDER`);
//   return true;
// };

const Row = memo(({
  data,
  onDeleteRow,
  onSubmit,
  isPending,
  addChild,
  activeRow,
  onChangeActiveRow,
  updateActiveFormRefLink,
  zIndex,
  lastZIndex,
  totalItemsCount,
  parentInfoRules,
  attributesBlacklist = [],
  isPendingEdit,
  isPendingDelete,
  parentServiceDomainId,
  parentSearchValue,
  selectedRow,
  setSelectedRow,
  selectedAsDependency,
  definitionsFilters = {},
  usedDomains,
  serviceType,
  serviceCurrency,
  getNodesListAndMakeTree,
  readOnly,
}) => {
  // console.log(`NODE: ${data.debugName}`);
  // console.log('DATA ', data);
  // console.log('ATTRIUBTES BLACK LIST ', attributesBlacklist);
  // console.log('PARENT RESOURCE ID ', parentResourceId);
  // console.count(`RERENDER ${data.debugName}`);
  const rowRef = useRef();
  const resourceCellRef = useRef();
  const priceCellRef = useRef();
  const billingCellRef = useRef();
  const quantityCellRef = useRef();
  const prevTotalItemsCount = usePrevious(totalItemsCount);
  const isZIndexAlreadyCalculated = useRef(false);
  const zIndexForChildren = useRef(0);
  const [isShowChildren, toggleIsShowChildren] = useToggle(true);
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [resourceCellOptions, setResourceCellOptions] = useState([]);
  const [isModalOpen, { openModal, closeModal }] = useModalLogic(false);
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle(false);
  const [isBreakdownDrawerOpen, toggleIsBreakdownDrawerOpen] = useToggle(false);

  if (prevTotalItemsCount !== totalItemsCount && isZIndexAlreadyCalculated.current) {
    // console.log('RECALCULATION');
    zIndexForChildren.current = lastZIndex.current - 1;

    if (data.children?.length) {
      lastZIndex.current -= 1;
    }
  }
  if (!isZIndexAlreadyCalculated.current) {
    isZIndexAlreadyCalculated.current = true;
    zIndexForChildren.current = lastZIndex.current - 1;

    if (data.children?.length) {
      lastZIndex.current -= 1;
    }
  }

  const formRef = useRef();
  const {
    resourcesOptions,
    definitionsList,
    getFilteredItems,
    serviceDomainsOptions,
    isPendingGetFilteredItems,
  } = useDictionariesApi();
  const {
    resourceAttributes,
    resourceAttributesDictionary,
    billingRules,
    quantityRules,
    billingRulesDictionary,
    quantityRulesDictionary,
  } = useServicesApi();

  const thisRowIsActive = activeRow === data.id;

  const rulesInfoForChildren = useMemo(() => {
    if (parentInfoRules) {
      return parentInfoRules;
    }
    if (data.level === 2) {
      return {
        resourceId: data.matchAttributeValues?.[0],
        billingRule: data.billingRule,
        quantityRule: data.quantityRule,
      };
    }
    return null;
  }, [parentInfoRules, data.level, data.matchAttributeValues]);
  const definitionsFiltersForChildren = useMemo(() => {
    // console.log(`NODE: ${data.debugName}`);
    // console.log('DATA ', data);
    if (data.level < 3) {
      return undefined;
    }
    if ((data.matchAttributeValues && data.matchAttributeValues.length === 0 && !data.siblingValues?.length)
      || !data.dictionaryId) {
      return definitionsFilters;
    }

    return {
      ...definitionsFilters,
      filters: [
        ...(definitionsFilters.filters || []),
        {
          dictionaryId: data.dictionaryId,
          type: data.matchAttributeValues?.length === 0 ? 'nin' : 'in',
          keys: data.matchAttributeValues?.length === 0
            ? data.siblingValues : [data.matchAttributeValues?.[0]?.toString?.()],
        },
      ],
    };
  }, [JSON.stringify(data.matchAttributeValues), JSON.stringify(data.siblingValues)]);

  const rowStyle = useMemo(() => {
    if (!parentSearchValue) {
      return undefined;
    }

    let search = parentSearchValue;
    let resourceCellDisplayValue = resourceCellOptions
      ?.find(I => I.value?.toString() === data.matchAttributeValues?.[0]?.toString())?.label
      || data.matchAttributeValues?.[0]?.toString();

    if (data.dictionaryId === 3 && resourceCellDisplayValue) {
      resourceCellDisplayValue = resourceCellDisplayValue.replace(' ', '');
      search = search?.replace(' ', '');
    }

    return ({
      display: resourceCellDisplayValue?.toLowerCase().includes(search)
        ? 'contents'
        : 'none',
    });
  }, [parentSearchValue, resourceCellOptions]);
  const CancelIcon = useMemo(() => {
    if (data.id === 'newRow' || (data.id !== 'newRow' && thisRowIsActive)) {
      return UndoIcon;
    }
    return DeleteIcon;
  }, [data.id, thisRowIsActive]);
  const SaveIcon = useMemo(() => (thisRowIsActive ? FloppyDiskIcon : EditIcon), [thisRowIsActive]);
  const linkColor = useMemo(() => {
    if (parentSearchValue) {
      return 'var(--orange50)';
    }
    if (selectedAsDependency) {
      return 'var(--blue50)';
    }

    return null;
  }, [parentSearchValue, selectedAsDependency]);
  const childrenModify = useMemo(() => {
    if (data.children && data.children.length > 1) {
      const childrenCopy = data.children.map(children => ({
        ...cloneDeep(children),
        siblingValues: data.children
          .filter(I => I.id !== children.id)
          .map(I => I.matchAttributeValues?.[0] || 'null'),
      }));

      if (data.level > 2) {
        const emptyValuesIndex = childrenCopy
          .findIndex(item => Array.isArray(item.matchAttributeValues)
            && item.matchAttributeValues.length === 0 && item.id !== 'newRow');

        if (emptyValuesIndex !== -1) {
          const [item] = childrenCopy.splice(emptyValuesIndex, 1);
          childrenCopy.push(item);
        }
      }

      return childrenCopy;
    }

    return data.children;
  }, [JSON.stringify(data.children)]);
  const modifySiblingValues = useMemo(() => {
    if (data.level === 1) {
      return usedDomains;
    }

    return data.siblingValues;
  }, [JSON.stringify(data.siblingValues), JSON.stringify(usedDomains)]);
  const rowErrors = useMemo(() => {
    if (activeRow !== data.id) {
      let errorObj = null;

      if ((data.priceType === null || data.priceType === undefined)
        && (data.childrenMatchAttributeId === null || data.childrenMatchAttributeId === undefined)) {
        errorObj = {
          color: 'danger',
          text: 'Price type not set',
        };
      }
      if (data.childrenMatchAttributeId && !data.children?.length) {
        if (errorObj) {
          errorObj.text += '\nNo child records. Please add at least one.';
        } else {
          errorObj = {
            color: 'warning',
            text: 'No child records. Please add at least one.',
          };
        }
      }

      return errorObj;
    }
    return null;
  }, [activeRow, data.id, data.priceType, data.childrenMatchAttributeId]);

  const getAttributesBlacklistForChildren = useCallback((childData) => {
    if (!childData.matchAttributeValues?.length || childData.matchAttributeValues?.[0] === null) {
      return attributesBlacklist;
    }

    return [
      ...attributesBlacklist,
      String(data.childrenMatchAttributeId),
    ];
  }, [data.children, attributesBlacklist]);
  const validationSchema = useMemo(() => TREE_ROW_SCHEMA({
    isFreeInput: data.dictionaryId === null,
    serviceType,
  }), [data.dictionaryId, serviceType]);
  const onAddChild = () => {
    if (!isShowChildren) {
      toggleIsShowChildren(true);
    }
    addChild(data);
  };
  const onCancelClick = (e) => {
    e.stopPropagation();
    if (isPending || readOnly) return;

    if (thisRowIsActive) {
      if (data.id === 'newRow') {
        onDeleteRow(data.id);
      } else {
        formRef.current.resetForm();
        onChangeActiveRow('');
      }
    } else if (!activeRow) {
      openModal(true);
    }
  };
  const onChevronClick = (e) => {
    e.stopPropagation();
    toggleIsShowChildren();
  };
  const onDeleteConfirm = () => onDeleteRow(data.id);
  const onRowDoubleClick = () => {
    if (!isPending && !readOnly) {
      onChangeActiveRow(data.id, formRef, rowRef);

      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }
  };
  const onRowClick = () => {
    if (!isPending && !thisRowIsActive && !readOnly) {
      setSelectedRow(selectedRow === data.id ? '' : data.id);
    }
  };
  const onSaveClick = (e) => {
    e?.stopPropagation();

    if (isPending || readOnly) return;

    if (thisRowIsActive) {
      if (!formRef.current?.dirty) {
        return;
      }

      if (data.children?.length) {
        const { values } = formRef?.current || {};

        if ((values?.childrenMatchAttributeId && (values?.childrenMatchAttributeId !== data.childrenMatchAttributeId))
        || (values?.priceType !== undefined && values?.priceType !== null)) {
          toggleIsBreakdownDrawerOpen();
          return;
        }
      }

      let firstRequiredField = '';

      formRef.current.handleSubmit();
      Object.entries(formRef.current.errors).forEach(([key, value]) => {
        if (!firstRequiredField) {
          firstRequiredField = key;
        }

        if (Array.isArray(value)) {
          value.forEach((item) => {
            Object.keys(item).forEach((itemKey) => {
              formRef.current.setFieldTouched(`${key}.${itemKey}`, true);
            });
          });
        } else {
          formRef.current.setFieldTouched(key, true);
        }
      });

      switch (firstRequiredField) {
        case 'matchAttributeValues':
          resourceCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
          break;
        case 'price':
        case 'scaleType':
          priceCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
          break;
        case 'billingRule':
          billingCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
          break;
        case 'quantityRule':
          quantityCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
          break;
        default: break;
      }
    } else if (!activeRow) {
      onChangeActiveRow(data.id, formRef, rowRef);
    }
  };
  const onSubmitModify = (values) => {
    onSubmit(values, () => {
      if (isBreakdownDrawerOpen) {
        toggleIsBreakdownDrawerOpen(false);
        getNodesListAndMakeTree();
      }
    });
  };
  const onChangeMatchAttribute = ({ form, value }) => {
    if (data.level === 2) {
      setAttributesOptions(
        sortBy(
          resourceAttributes.filter(attribute => String(attribute.resourceId) === String(value)),
          ['label'],
        ),
      );

      if (form.dirty) {
        form.setFieldValue('priceTypeCell', undefined);
        form.setFieldValue('billingRule', undefined);
        form.setFieldValue('quantityRule', undefined);
        form.setFieldValue('scaleCounterGroupBy', undefined);
      }
    }
  };
  const onChangePriceTypeCell = ({ form, value }) => {
    if (form.dirty) {
      if (value?.includes?.('/price')) {
        form.setFieldValue('childrenMatchAttributeId', null);
        form.setFieldValue('priceType', +value.split('/')[0]);

        switch (value) {
          case '1/price':
            form.setFieldValue('price', undefined);
            break;
          case '2/price':
            form.setFieldValue(
              'price',
              [
                {
                  tier: '',
                  price: '',
                },
                {
                  tier: null,
                  price: '',
                },
              ],
            );
            break;
          default:
            form.setFieldValue('price', undefined);
            break;
        }
      } else {
        form.setFieldValue('priceType', undefined);
        form.setFieldValue('price', undefined);
        form.setFieldValue('childrenMatchAttributeId', value);
        if (data.level > 2) {
          form.setFieldValue('billingRule', undefined);
          form.setFieldValue('quantityRule', undefined);
        }
      }
    }
  };
  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (data.id === 'newRow') {
      rowRef.current?.children?.[1]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      updateActiveFormRefLink(formRef);
    }
  }, []);
  useEffect(() => {
    if (data.level === 1) {
      setAttributesOptions([
        {
          value: 4,
          label: 'Resource',
        },
      ]);
    } else if (data.level >= 3) {
      setAttributesOptions(
        sortBy(
          resourceAttributes.filter((attribute) => {
            const isMatchingResourceId = String(attribute.resourceId) === String(parentInfoRules?.resourceId);
            const isNotMatchingAttributeId = String(attribute.value) !== String(data.matchAttributeId);
            const isNotInBlacklist = !attributesBlacklist.includes(String(attribute.value));
            const isCurrentValue = String(attribute.value) === String(data.childrenMatchAttributeId);

            return (isMatchingResourceId && isNotMatchingAttributeId && isNotInBlacklist) || isCurrentValue;
          }),
          ['label'],
        ),
      );
    }
  }, [resourceAttributes, attributesBlacklist?.length]);
  useEffect(() => {
    // если мы выбираем монетарные паки
    if (activeRow === data.id && data.matchAttributeId === 500101) {
      getFilteredItems({
        dictionaryId: data.dictionaryId,
        body: {
          ...definitionsFilters,
          filters: [...(definitionsFilters?.filters || [])],
          definitionFilters: [
            {
              field: 'currency',
              type: 'in',
              values: [serviceCurrency],
            },
          ],
        },
        successCallback: (res) => {
          setResourceCellOptions(definitionsList[data.dictionaryId]?.reduce((acc, item) => {
            if (res?.data?.some(key => key === item.value)) {
              acc.push(item);
            }
            return acc;
          }, []));
        },
      });
    } else {
      switch (data.level) {
        case 1:
          setResourceCellOptions(serviceDomainsOptions);
          break;
        case 2:
          setResourceCellOptions(resourcesOptions.filter(resource => !parentServiceDomainId || String(resource.serviceDomainId) === String(parentServiceDomainId)));
          break;
        case 3:
          setResourceCellOptions(definitionsList[data.dictionaryId]);
          break;
        default:
          if (data.dictionaryId) {
            if (activeRow === data.id) {
              getFilteredItems({
                dictionaryId: data.dictionaryId,
                body: definitionsFilters,
                successCallback: (res) => {
                  setResourceCellOptions(definitionsList[data.dictionaryId]?.reduce((acc, item) => {
                    if (res?.data?.some(key => key === item.value)) {
                      acc.push(item);
                    }
                    return acc;
                  }, []));
                },
              });
            } else {
              setResourceCellOptions(definitionsList[data.dictionaryId]);
            }
          }
          break;
      }
    }
  }, [activeRow]);

  useKey('Enter', () => {
    if (thisRowIsActive) {
      onSaveClick();
    }
  }, {}, [thisRowIsActive]);
  // console.log('**************************************');

  return (
    <>
      <Formik
        initialValues={{
          ...data,
          // price: Array.isArray(data.price) ? data.price : `${data.price}`,
          matchAttributeValues: Array.isArray(data.matchAttributeValues)
            ? data.matchAttributeValues?.[0] || 'null'
            : undefined,
          priceTypeCell: data.childrenMatchAttributeId
            ? data.childrenMatchAttributeId
            : Number.isInteger(data.priceType) ? `${data.priceType}/price` : undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitModify}
        innerRef={formRef}
        enableReinitialize
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          dirty,
        }) => (
          <Form
            className={cx(styles.row, {
              [styles.row_active]: thisRowIsActive,
              [styles.row_selected]: selectedRow === data.id,
              [styles.row_withActiveSearch]: !!searchValue,
              [styles.row_readOnly]: readOnly,
            })}
            style={rowStyle}
            onClick={onRowClick}
            onDoubleClick={onRowDoubleClick}
            ref={rowRef}
          >
            {/* Active indicator column cell */}
            <div />
            {/* Chevron column cell */}
            <div className={styles.chevronCell}>
              {!!data.children?.length && (
                <Icon
                  icon="chevron-down"
                  size={16}
                  className={cx(styles.chevron, {
                    [styles.chevron_reversed]: !isShowChildren,
                  })}
                  onClick={onChevronClick}
                />
              )}
            </div>
            {/* Errors column cell */}
            {!readOnly && (
              <div>
                {rowErrors && (
                  <TriangleIcon
                    className={cx(Color[rowErrors.color], styles.rowError)}
                    title={rowErrors.text}
                  />
                )}
              </div>
            )}
            {/* Resource column cell */}
            <ResourceCell
              level={data.level}
              options={resourceCellOptions}
              freeInput={data.dictionaryId === null}
              withControls={!!values.childrenMatchAttributeId && data.id !== 'newRow' && !thisRowIsActive && !readOnly}
              withSearch={!!data.children?.length}
              onAdd={onAddChild}
              onMultiAdd={toggleIsDrawerOpen}
              disabled={!thisRowIsActive || data.children?.length}
              isEdit={thisRowIsActive}
              addChildrenDisabled={!!activeRow}
              zIndex={zIndex}
              cellRef={resourceCellRef}
              touched={touched}
              errors={errors}
              searchValue={searchValue}
              onChangeSearch={handleSearch}
              linkColor={linkColor}
              title={thisRowIsActive && data.children?.length
                ? 'Can\'t change node resource that has children'
                : undefined}
              siblingValues={modifySiblingValues}
              isLoading={isPendingGetFilteredItems && thisRowIsActive}
            />
            {/* Price type column cell */}
            <PriceTypeCell
              dependsOptions={attributesOptions}
              disabled={data.level === 1 || !thisRowIsActive}
              isEdit={thisRowIsActive}
              dependsOnDisabled={data.level === 2 && !values.matchAttributeValues}
              importFromFileDisabled={!!Object.keys(errors)?.filter(key => key !== 'priceTypeCell')?.length}
              title={thisRowIsActive && data.children?.length
                ? 'Cannot change the attribute for a branching node that has children'
                : undefined}
              serviceType={serviceType}
              data={values}
              serviceCurrency={serviceCurrency}
              parentInfoRules={parentInfoRules}
            />
            {/* Price column cell */}
            <PriceCell
              priceTypeCellValue={values.priceTypeCell}
              disabled={!thisRowIsActive}
              isEdit={thisRowIsActive}
              errors={errors}
              touched={touched}
              cellRef={priceCellRef}
              counterGroupOptions={attributesOptions}
              counterGroupDisabled={data.level === 2 && !values.matchAttributeValues}
              serviceType={serviceType}
            />
            {/* Billing rule column cell */}
            <RuleCell
              name="billingRule"
              level={data.level}
              parentResourceId={parentInfoRules?.resourceId}
              values={values}
              rules={billingRules}
              disabled={!thisRowIsActive || (data.level === 2 && !values.matchAttributeValues)}
              isEdit={thisRowIsActive}
              valueByDefault={parentInfoRules?.billingRule}
              dictionary={billingRulesDictionary}
              cellRef={billingCellRef}
              priceType={values.priceType}
              serviceType={serviceType}
            />
            {/* Quantity rule column cell */}
            <RuleCell
              name="quantityRule"
              level={data.level}
              parentResourceId={parentInfoRules?.resourceId}
              values={values}
              rules={quantityRules}
              disabled={!thisRowIsActive || (data.level === 2 && !values.matchAttributeValues)}
              isEdit={thisRowIsActive}
              valueByDefault={parentInfoRules?.quantityRule}
              dictionary={quantityRulesDictionary}
              cellRef={quantityCellRef}
              priceType={values.priceType}
              serviceType={serviceType}
            />
            {/* Actions column cell */}
            {!readOnly && (
              <div
                className={cx(styles.buttonsWrapper, {
                  [styles.buttonsWrapper_alwaysVisible]: data.id === 'newRow',
                  [styles.buttonsWrapper_alwaysHidden]: readOnly,
                })}
              >
                {isPendingDelete && thisRowIsActive
                  ? <Spinner size={20} color="danger" />
                  : (
                    <CancelIcon
                      className={cx(
                        styles.iconButton,
                        {
                          [styles.iconButton_disabled]: isPending || (!!activeRow && !thisRowIsActive),
                          [Color.danger]: CancelIcon === DeleteIcon,
                        },
                      )}
                      onClick={onCancelClick}
                    />
                  )}
                {isPendingEdit && thisRowIsActive
                  ? <Spinner size={20} color="primary" />
                  : (
                    <SaveIcon
                      className={cx(
                        styles.iconButton,
                        Color.primary,
                        {
                          [styles.iconButton_disabled]: isPending || (!!activeRow && !thisRowIsActive)
                          || (!dirty && thisRowIsActive),
                        },
                      )}
                      onClick={onSaveClick}
                      title={!dirty && thisRowIsActive ? 'Nothing to save' : undefined}
                    />
                  )}
              </div>
            )}
            <OnChangeComponent field="matchAttributeValues" onChange={onChangeMatchAttribute} />
            <OnChangeComponent field="priceTypeCell" onChange={onChangePriceTypeCell} />
          </Form>
        )}
      </Formik>
      {zIndexForChildren.current && !!data.children?.length && (
        <div
          className={cx(styles.childrenWrapper, {
            [styles.childrenWrapper_expand]: isShowChildren && rowStyle?.display !== 'none',
          })}
        >
          {childrenModify?.map(row => (
            <Row
              key={row.id}
              data={row}
              onDeleteRow={onDeleteRow}
              addChild={addChild}
              isPending={isPending}
              onSubmit={onSubmit}
              activeRow={activeRow}
              onChangeActiveRow={onChangeActiveRow}
              updateActiveFormRefLink={updateActiveFormRefLink}
              lastZIndex={lastZIndex}
              zIndex={zIndexForChildren.current}
              totalItemsCount={totalItemsCount}
              parentInfoRules={rulesInfoForChildren}
              attributesBlacklist={getAttributesBlacklistForChildren(row)}
              isPendingDelete={isPendingDelete}
              isPendingEdit={isPendingEdit}
              parentServiceDomainId={data.level === 1 ? data.matchAttributeValues[0] : undefined}
              parentSearchValue={searchValue?.trim()?.toLowerCase()}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              selectedAsDependency={thisRowIsActive || selectedRow === data.id || selectedAsDependency}
              definitionsFilters={definitionsFiltersForChildren}
              serviceType={serviceType}
              getNodesListAndMakeTree={getNodesListAndMakeTree}
              serviceCurrency={serviceCurrency}
              readOnly={readOnly}
            />
          ))}
        </div>
      )}
      {isModalOpen && (
        <ConfirmModal
          onConfirm={onDeleteConfirm}
          isPending={isPendingDelete}
          closeModal={closeModal}
          isDelete
        />
      )}
      <MultiAddDrawer
        isOpen={isDrawerOpen}
        toggleOpen={toggleIsDrawerOpen}
        resourceAttributesDictionary={resourceAttributesDictionary}
        data={data}
        resourceCellOptions={resourceCellOptions}
        serviceDomainsOptions={serviceDomainsOptions}
        resourcesOptions={resourcesOptions}
        definitionsList={definitionsList}
        definitionsFiltersForChildren={definitionsFiltersForChildren}
        getFilteredItems={getFilteredItems}
        getNodesListAndMakeTree={getNodesListAndMakeTree}
      />
      <BreakdownDrawer
        isOpen={isBreakdownDrawerOpen}
        toggleOpen={toggleIsBreakdownDrawerOpen}
        data={data}
        resourceCellOptions={resourceCellOptions}
        resourceAttributesDictionary={resourceAttributesDictionary}
        getFilteredItems={getFilteredItems}
        definitionsFiltersForChildren={definitionsFiltersForChildren}
        definitionsList={definitionsList}
        values={formRef.current?.values}
        getNodesListAndMakeTree={getNodesListAndMakeTree}
        submitForm={formRef.current?.handleSubmit}
      />
    </>
  );
});

export default Row;
