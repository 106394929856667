import React, { useEffect, useState } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import { usePortalsApi, useDictionariesApi } from 'hooks/api';
import Modal from 'components/Modal';
import { useScreen } from 'hooks';
import { ConfirmModal } from 'components/modals';
import endpoints from 'consts/endpoints';
import { PORTALS } from 'consts/columns';

import PortalForm from './PortalForm';
import styles from './PortalsScreen.module.scss';

const PortalsScreen = ({ screenRef }) => {
  const {
    portals,
    portalsMeta,
    getPortals,
    postPortal,
    deletePortal,
    putPortal,
    isPendingGetPortals,
    isPendingPostPortal,
    isPendingDeletePortal,
    isPendingPutPortal,
    lastUpdatedGetPortals,
  } = usePortalsApi();
  const { getCompaniesDictionary, getUsersDictionary } = useDictionariesApi();
  const [serverIP, setServerIP] = useState('');
  const onRowClickCallback = ({ brandSettings: { loginTitle, pageTitle, ...brandSettings }, ...data }) => ({
    ...data,
    ...(Object.entries(brandSettings)?.reduce((acc, [key, filename]) => {
      acc[key] = filename ? endpoints.getPortalFileUrl(data.id, typeof filename === 'string'
        ? filename : filename[0]) : null;
      return acc;
    }, {}) || {}),
    loginTitle: Object.entries(loginTitle || {})?.map(([key, value]) => ({
      language: key,
      text: value,
    })),
    pageTitle,
  });
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getPortals,
    deleteFunc: deletePortal,
    onRowClickCallback,
  });
  const onSubmit = ({
    id,
    companyId,
    name,
    fqdn,
    certificateManagementOption,
    loginTitle,
    createdAt,
    updatedAt,
    createdBy,
    updatedBy,
    status,
    error,
    legalEntityCount,
    pageTitle,
    poweredBy,
    ...values
  }) => {
    const form = new FormData();

    form.append('portal', new Blob([
      JSON.stringify({
        companyId,
        name,
        fqdn,
        certificateManagementOption,
        status,
        pageTitle,
        poweredBy,
        loginTitle: loginTitle?.reduce((acc, item) => {
          acc[item.language] = item.text;
          return acc;
        }, {}),
      }),
    ], {
      type: 'application/json',
    }));
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value !== 'string') {
        form.append(key, value);
      }
    });

    (id ? putPortal : postPortal)({
      id,
      body: form,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
    getUsersDictionary();

    fetch(`https://api.api-ninjas.com/v1/dnslookup?domain=${process.env.NODE_ENV === 'production' ? window.location.host : 'mkitdev2.alarislabs.com'}`, {
      contentType: 'application/json',
      headers: {
        'X-Api-Key': 'jQcmcpJQRB6A0RP7ykzR7w==aLxmy9zeawS8IWOS',
      },
    })
      .then(response => response.json())
      .then((data) => {
        setServerIP(data?.[0]?.value || 'Unknown');
      })
      .catch(() => setServerIP('Unknown'));
  }, []);

  return (
    <div className={styles.screen}>
      <Table
        limit={limit}
        total={portalsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.PORTALS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.PORTALS.ADD"
        columnDefs={PORTALS}
        rowData={portals}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetPortals || !lastUpdatedGetPortals}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={PORTALS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.PORTALS.PORTAL"
            editableRecord={editableRecord}
            size="lg"
          >
            <PortalForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              initialValues={editableRecord}
              isPending={isPendingPostPortal || isPendingPutPortal || isPendingDeletePortal}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
              serverIP={serverIP}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default PortalsScreen;
