/* eslint-disable react/no-unstable-nested-components */
import React, {
  useState, useMemo, useRef, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';

import { useDictionariesApi, useServicesApi } from 'hooks/api';
import TextField from 'components/fields/TextField';
import RadioButtonField from 'components/fields/RadioButtonField';
import Button from '@setproduct-ui/core/Button';
import Table from 'components/Table';
import ScrollableTabs from 'components/ScrollableTabs';
import DropdownField from 'components/fields/DropdownField';

import CustomDialog from './CustomDialog';
import ColumnHeader from './ColumnHeader';
import TreeStructure from './TreeStructure';
import styles from './ImportConfigurationModal.module.scss';

import TreeTable from '../ServiceTab/Table';

const PREVIEW_LIMITS_OPTIONS = [
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: undefined,
    label: 'Unlimited',
  },
];
const ImportConfigurationModal = ({
  isOpen,
  onClose,
  sourceNode,
  imperativeRef,
}) => {
  const {
    resourcesDictionary,
    definitionsList,
  } = useDictionariesApi();
  const {
    resourceAttributesDictionary,
    getRateImportFilePreview,
    rateImportInfo,
    patchRateImportSettings,
    postRateImportPreview,
    postApplyRateImport,
    isPendingGetRateImportFilePreview,
    isPendingPatchRateImportSettings,
    isPendingPostRateImportPreview,
    isPendingPostApplyRateImport,
  } = useServicesApi();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDuplicateOption, setSelectedDuplicateOption] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isPreview, toggleIsPreview] = useToggle(false);
  const [limit, setLimit] = useState(100);
  const tableRef = useRef();
  const rateImportConfig = useSelector(state => state.states.rateImportConfig);

  const {
    attribute,
    attributeValue,
  } = useMemo(() => {
    if (sourceNode) {
      if (sourceNode.level === 2) {
        return {
          attribute: 'Resource',
          attributeValue: resourcesDictionary[sourceNode.matchAttributeValues]?.name,
        };
      }

      return {
        attribute: resourceAttributesDictionary[sourceNode.matchAttributeId]?.displayName,
        attributeValue: sourceNode.matchAttributeValues === 'null'
          ? 'Others'
          : sourceNode.dictionaryId
            ? definitionsList[sourceNode.dictionaryId]?.find(item => item.value === sourceNode.matchAttributeValues)?.label
            : sourceNode.matchAttributeValues,
      };
    }

    return {};
  }, [sourceNode]);
  const {
    rowData,
    cols,
  } = useMemo(() => ({
    rowData: rateImportInfo?.filePreview?.map((item, index) => ({
      ...item,
      id: index + 1,
    })),
    cols: [
      {
        field: 'id',
        maxWidth: 50,
        headerName: '',
        cellStyle: props => ({
          borderRight: '1px solid var(--grey10)',
          color: props.node.selected ? 'var(--blue50)' : 'initial',
          fontWeight: props.node.selected ? 'bold' : 'normal',
        }),
        sortable: false,
      },
      ...(Object.keys(rateImportInfo?.filePreview?.[0] || {}) || []).map(key => ({
        field: key,
        flex: 1,
        sortable: false,
      })),
    ],
  }), [rateImportInfo.filePreview]);
  const tabs = useMemo(() => ([
    {
      id: 'tree',
      title: 'Preview',
      withoutCross: true,
    },
    {
      id: 'errors',
      title: `Errors ${rateImportInfo?.preview?.errors?.length || 0}`,
      withoutCross: true,
    },
  ]), [rateImportInfo]);
  const {
    duplicateError,
    priceColError,
    attributeColError,
  } = useMemo(() => ({
    duplicateError: !selectedDuplicateOption,
    priceColError: !rateImportInfo?.columns?.some(col => col.entityType === 2 && col.entityId === 12),
    attributeColError: !rateImportInfo?.columns?.filter(col => col.entityType === 1)?.length,
  }), [selectedDuplicateOption, rateImportInfo]);
  const externalData = useMemo(() => {
    if (isPreview && rateImportConfig?.sourceNode) {
      return ([
        {
          ...rateImportConfig?.sourceNode,
          matchAttributeValues: [String(rateImportConfig?.sourceNode?.matchAttributeValues)],
          childrenMatchAttributeId: rateImportInfo?.treeStructureElements?.[0]?.attributeId,
          parentId: null,
        },
        ...(rateImportInfo?.preview?.previewItems?.map(item => ({
          ...item,
          id: item.Id,
          parentId: item?.parentId || rateImportConfig?.sourceNode.id,
        })) || []),
      ]);
    }
    return [];
  }, [rateImportConfig, rateImportInfo]);

  const onSelectionChanged = ({ api }) => {
    const newStartRow = api.getSelectedRows()?.[0]?.id;

    patchRateImportSettings({
      taskId: rateImportConfig.taskId,
      body: {
        startRow: newStartRow,
      },
      successCallback: () => {
        setSelectedRow(newStartRow);
        setTimeout(tableRef.current?.redrawRows);
      },
    });
  };
  const onChangeDuplicateOption = (newVal) => {
    patchRateImportSettings({
      taskId: rateImportConfig.taskId,
      body: {
        duplicateHandlingOption: newVal,
      },
      successCallback: () => setSelectedDuplicateOption(newVal),
    });
  };
  const onCloseModify = () => {
    onClose();
    setSelectedRow(null);
    setSelectedDuplicateOption(null);
    toggleIsPreview(false);
  };
  const onNextClick = () => {
    postRateImportPreview({
      taskId: rateImportConfig?.taskId,
      successCallback: () => toggleIsPreview(),
    });
  };
  const onApplyImportClick = () => {
    postApplyRateImport({
      taskId: rateImportConfig?.taskId,
      successCallback: () => {
        onCloseModify();
        imperativeRef?.current?.redrawTree();
      },
    });
  };
  const renderPanel = ({ id }) => {
    if (id === 'tree') {
      if (externalData?.length > 2000) {
        return (
          <span>
            At this time, unfortunately, we will not support displaying a rate tree of this size. But you can still complete the import for use.
          </span>
        );
      }
      return (
        <TreeTable
          externalData={externalData}
          serviceType={rateImportConfig?.serviceType}
          currency={rateImportConfig?.serviceCurrency}
          initialLevel={rateImportConfig?.sourceNode?.level}
          className={styles.treeTable}
          parentInfoRules={rateImportConfig?.parentInfoRules}
          readOnly
        />
      );
    }
    return (
      <div className={styles.errorsList}>
        {rateImportInfo?.preview?.errors?.map(item => (
          <div className={styles.errorListItem} key={`${item.row}/${item.column}`}>
            <span>Error:</span>
            <span>{`${item.err || 'unknown'};`}</span>
            column:
            <b>{`${item.column};`}</b>
            row:
            <b>{`${item.row}`}</b>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (rateImportConfig.taskId) {
      getRateImportFilePreview({
        taskId: rateImportConfig.taskId,
        limit,
      });
    }
  }, [rateImportConfig.taskId, limit]);
  useEffect(() => {
    if (!isPreview && selectedRow) {
      tableRef?.current?.selectNode({ id: selectedRow });
    }
  }, [selectedRow, isPreview]);

  return (
    <CustomDialog
      isOpen={isOpen}
      classNames={{
        modal: styles.modal,
        title: styles.title,
      }}
      title={(
        <>
          {`${attribute}:`}
          <span>{attributeValue}</span>
          - Import from file
        </>
      )}
      onClose={onCloseModify}
    >
      {isPreview ? (
        <div className={styles.tabsContainer}>
          <ScrollableTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsList={tabs}
            styles={{ wrapper: { flex: 1 } }}
            renderPanel={renderPanel}
          />
          <div className={styles.tabsButtonsWrapper}>
            <Button
              text="Back"
              onClick={toggleIsPreview}
              disabled={isPendingPostApplyRateImport}
              view="outlined"
            />
            <Button
              text="Next"
              onClick={onApplyImportClick}
              loading={isPendingPostApplyRateImport}
              color="primary"
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.tableWrapper}>
            <Table
              columnDefs={cols}
              rowData={rowData}
              className={styles.table}
              withPagination={false}
              onSelectionChanged={onSelectionChanged}
              rowSelection="single"
              tableRef={tableRef}
              isPending={isPendingGetRateImportFilePreview}
              agColumnHeader={props => (
                <ColumnHeader
                  resourceAttributes={rateImportConfig.dependsOptions}
                  {...props}
                />
              )}
            />
            <div className={styles.tablePagination}>
              Rows limit
              <DropdownField
                value={limit}
                onChange={setLimit}
                options={PREVIEW_LIMITS_OPTIONS}
                dense
              />
            </div>
          </div>
          <div className={styles.settings}>
            <span className={styles.settingsTitle}>
              Settings
            </span>
            <div className={styles.selectRowFieldWrapper}>
              <TextField
                value={selectedRow}
                label="Start row"
                classNames={{
                  container: !selectedRow && styles.selectRowField,
                }}
                clearButton={false}
                readOnly
                dense
              />
              {!selectedRow && (
                <div className={styles.selectRowFieldHintOverlay}>
                  <span>Select start row first</span>
                  Just click on the first row with actual data to import (excluding header rows)
                </div>
              )}
            </div>
            <RadioButtonField
              value={selectedDuplicateOption}
              onChange={onChangeDuplicateOption}
              label="Duplicate handling"
              color="default"
              options={[
                {
                  value: 1,
                  label: 'Take first',
                },
                {
                  value: 2,
                  label: 'Take last',
                },
                {
                  value: 3,
                  label: 'Show error (do not import)',
                },
              ]}
              disabled={isPendingPatchRateImportSettings}
            />
            <TreeStructure
              attribute={attribute}
              attributeValue={attributeValue}
            />
            <div className={styles.hintsContainer}>
              {duplicateError && (
                <div className={styles.hintRow}>
                  <Icon icon="warning-sign" color="var(--red60)" size={14} />
                  <span>Select duplicate handling option</span>
                </div>
              )}
              {attributeColError && (
                <div className={styles.hintRow}>
                  <Icon icon="warning-sign" color="var(--red60)" size={14} />
                  <span>No attribute column is set, you need to set at least one</span>
                </div>
              )}
              {priceColError ? (
                <div className={styles.hintRow}>
                  <Icon icon="warning-sign" color="var(--red60)" size={14} />
                  <span>No Price column is set</span>
                </div>
              ) : (
                <>
                  {!rateImportInfo?.columns?.some(col => col.entityType === 2 && col.entityId === 21) && (
                    <div className={styles.hintRow}>
                      <Icon icon="info-sign" color="var(--blue60)" size={14} />
                      <span>No Billing rule column is set</span>
                    </div>
                  )}
                  {!rateImportInfo?.columns?.some(col => col.entityType === 2 && col.entityId === 31) && (
                    <div className={styles.hintRow}>
                      <Icon icon="info-sign" color="var(--blue60)" size={14} />
                      <span>No Quantity rule column is set</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.settingsButtonsWrapper}>
              <Button
                text="Cancel"
                view="outlined"
                onClick={onCloseModify}
              />
              <Button
                text={isPreview ? 'Import' : 'Next'}
                color="primary"
                onClick={onNextClick}
                loading={isPendingPostRateImportPreview}
                disabled={duplicateError || attributeColError || priceColError}
              />
            </div>
          </div>
        </>
      )}
    </CustomDialog>
  );
};

export default ImportConfigurationModal;
