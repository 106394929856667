import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Tab, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'components/index';
import DropdownField from 'components/fields/DropdownField';
import TextField from 'components/fields/TextField';
import Callout from '@setproduct-ui/core/Callout';
import Tabs from '@setproduct-ui/core/Tabs';
import Chips from '@setproduct-ui/core/Chips';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import IsFormChanged from 'components/forms/IsFormChanged';
import {
  SENDER_STATUSES,
  APPLICATION_STATUSES,
  RCS_VENDOR_TYPES,
} from 'consts';
import { generateUuid } from 'helpers';
import { SENDER_SCHEMA } from 'consts/validationSchemas';
import { RCSIcon } from 'assets/icons';

import VisualSettings from './VisualSettings';
import JSONFileField from './JSONFileField';
import GSMAFields from './GSMAFields';
import Footer from './Footer';

import './style.scss';

const channelsOptions = [
  { value: 2, label: 'RCS', icon: <RCSIcon width={20} height={20} /> },
];

const ApplicationForm = ({
  partnersOptions,
  agreements,
  initialValues = {
    color: '#000000',
    phone: [
      {
        value: null,
        label: null,
      },
    ],
    website: [
      {
        value: null,
        label: null,
      },
    ],
    email: [
      {
        value: null,
        label: null,
      },
    ],
    senderStatus: 0,
  },
  senderValues,
  isPending,
  readOnly,
  leftButtonText,
  onLeftButtonClick,
  rightButtonText,
  onRightButtonClick,
  showControls = true,
  highlightChanged,
  onCancel,
  currentUserId,
  showHeader,
  onSenderClick,
  showTabs,
  formValuesRef,
  isFormPristine,
  mode,
}) => {
  const dropdownFieldStyles = ({ marginRight, marginLeft }) => ({
    container: {
      width: '100%',
      marginRight,
      marginLeft,
    },
  });
  const generateButtonStyles = {
    position: 'absolute',
    right: 8,
    top: 12,
    cursor: 'pointer',
  };

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('display');
  const dispatch = useDispatch();
  const { activeTab: activeScreenTab } = useSelector(state => state.states.routes);

  const generalInfoClass = cx('application-form__general-information', {
    'application-form__general-information_with-border': readOnly && !showTabs,
  });
  const getAgreementsOptions = companyId => agreements.reduce((acc, item) => {
    if (item.companyId === companyId) {
      acc.push({
        label: `${item.agreementCode} (${item.currency})`,
        value: item.id,
      });
    }
    return acc;
  }, []);
  const onChangeCompany = ({ form }) => {
    if (form.dirty) {
      form.setFieldValue('agreementId', undefined);
    }
  };
  const checkFunction = (values) => {
    if (!isEmpty(values) && formValuesRef?.current) {
      formValuesRef.current = values;

      if (activeScreenTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = ({
    agreementId,
    vendorType,
    projectNumber,
    agentId,
    rbmConfig,
    login,
    password,
    tokenDuration,
    id,
    status,
    companyId: formCompanyId,
    vendorConfig,
    webHookGuid,
    senderStatus,
    senderId,
    senderIdId,
    ...values
  }) => {
    onRightButtonClick({
      id,
      companyId: formCompanyId,
      senderId,
      senderIdId,
      status: status || 2,
      channel: 2,
      senderStatus,
      displayName: values.displayName,
      logoUrl: values.logoUrl,
      agreementId,
      vendorType,
      vendorConfig: vendorType === 'google_rbm' ? (vendorConfig || null) : {
        login,
        password,
        tokenDuration,
      },
      webHookGuid,
      properties: {
        displayName: values.displayName,
        logoUrl: values.logoUrl,
        bannerImageUrl: values.bannerImageUrl,
        description: values.description,
        phone: values.phone,
        website: values.website,
        email: values.email,
        ppPage: values.ppPage,
        tcPage: values.tcPage,
        color: values.color,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      enableReinitialize
      validationSchema={readOnly ? undefined : SENDER_SCHEMA}
      validateOnMount
    >
      {({
        values,
        setFieldTouched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          className="application-form"
        >
          <div className="application-form__shadow" />
          {
            showHeader && (
              <div className="application-form__header">
                <div className="application-form__cell">
                  <Chips
                    {...APPLICATION_STATUSES[initialValues.status.toString()]}
                    round={false}
                    type="dense"
                  />
                  <Typography className="application-form__label">
                    INSTANCES.STATUS
                  </Typography>
                </div>
                {
                  initialValues.senderId && (
                    <div className="application-form__cell">
                      <span
                        className="application-form__sender-link"
                        role="presentation"
                        onClick={onSenderClick}
                      >
                        {initialValues.senderId}
                      </span>
                      <Typography className="application-form__label">
                        INSTANCES.SENDER_ID
                      </Typography>
                    </div>
                  )
                }
              </div>
            )
          }
          <div className={generalInfoClass}>
            <Field
              name="companyId"
              component={DropdownField}
              options={partnersOptions}
              styles={dropdownFieldStyles({ marginRight: (readOnly && !showTabs) ? 24 : 12 })}
              label="INSTANCES.COMPANY"
              placeholder="PLACEHOLDERS.SELECT_PARTNER"
              disabled={readOnly}
              isRequired
            />
            {
              (!readOnly || showTabs) && (
                <Field
                  name="agreementId"
                  component={DropdownField}
                  label="INSTANCES.AGREEMENT"
                  placeholder="PLACEHOLDERS.SELECT"
                  options={getAgreementsOptions(values.companyId)}
                  styles={dropdownFieldStyles({ marginLeft: 12, marginRight: 24 })}
                  disabled={readOnly || !values.companyId}
                  isRequired
                />
              )
            }
            <DropdownField
              options={channelsOptions}
              value={2}
              styles={dropdownFieldStyles(
                { marginLeft: 24, marginRight: (readOnly && !showTabs) ? 0 : 12 },
              )}
              label="INSTANCES.CHANNEL"
              placeholder="PLACEHOLDERS.SELECT"
              disabled
              isRequired
            />
            {
              (!readOnly || showTabs) && (
                <Field
                  name="senderStatus"
                  component={DropdownField}
                  label="INSTANCES.STATUS"
                  placeholder="PLACEHOLDERS.SELECT"
                  options={SENDER_STATUSES}
                  styles={dropdownFieldStyles({ marginLeft: 12 })}
                  disabled={readOnly}
                  isRequired
                />
              )
            }
          </div>
          {
            readOnly && !showTabs ? (
              <VisualSettings
                values={values}
                errors={errors}
                style={{ maxHeight: 'calc(100vh - 215px)' }}
                readOnly={readOnly}
                showTabs={showTabs}
                initialValues={initialValues}
                highlightChanged={highlightChanged}
                senderValues={senderValues}
              />
            ) : (
              <Tabs
                type="def"
                view="flat"
                id="application-tabs"
                fill
                color="primary"
                className="application-form__tabs"
                selectedTabId={activeTab}
                onChange={tabId => setActiveTab(tabId)}
              >
                <Tab
                  id="display"
                  title={t('SCREENS.SENDER_IDS.PROPERTIES')}
                  panel={(
                    <VisualSettings
                      values={values}
                      errors={errors}
                      readOnly={readOnly}
                      showTabs={showTabs}
                      initialValues={initialValues}
                      highlightChanged={highlightChanged}
                      senderValues={senderValues}
                    />
                  )}
                />
                <Tab
                  id="tech"
                  title={t('SCREENS.SENDER_IDS.CONFIGURATION')}
                  panel={(
                    <div className="application-form__content">
                      <div className="application-form__column">
                        <Typography className="application-form__subtitle application-form__subtitle_required">
                          INSTANCES.SENDER_IDS.WEBHOOK_GUID
                        </Typography>
                        <Field
                          name="webHookGuid"
                          component={TextField}
                          rightElement={!values.webHookGuid && !readOnly && (
                            <Icon
                              icon="new-link"
                              size={20}
                              color="var(--blue70)"
                              style={generateButtonStyles}
                              onClick={() => setFieldValue('webHookGuid', generateUuid())}
                            />
                          )}
                          disabled={readOnly}
                          placeholder="PLACEHOLDERS.ENTER_VALUE"
                          full
                        />
                        <div className="application-form__hor-divider" />
                        <Typography className="application-form__subtitle application-form__subtitle_required">
                          INSTANCES.VENDOR_TYPE
                        </Typography>
                        <Field
                          name="vendorType"
                          component={DropdownField}
                          options={RCS_VENDOR_TYPES}
                          placeholder="PLACEHOLDERS.SELECT"
                          disabled={readOnly}
                        />
                        {values.vendorType === 'alaris_gsma' && (
                          <GSMAFields readOnly={readOnly} />
                        )}
                        {values.vendorType === 'google_rbm' && (
                          <>
                            <Typography
                              className="application-form__subtitle application-form__subtitle_required"
                              style={{ marginTop: 24 }}
                            >
                              SCREENS.SENDER_IDS.API_ACCESS
                            </Typography>
                            <Field
                              component={JSONFileField}
                              disabled={readOnly}
                              name="vendorConfig"
                            />
                          </>
                        )}
                      </div>
                      <div className="application-form__column">
                        <Callout
                          view="smooth"
                          title={t('INSTANCES.SENDER_IDS.WEBHOOK_GUID')}
                          icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                          className="application-form__callout"
                          style={{ marginBottom: 24 }}
                          dense
                        >
                          {t('SCREENS.SENDER_IDS.WEBHOOK_DESC')}
                        </Callout>
                        {
                          values.vendorType === 'google_rbm' && (
                            <Callout
                              view="smooth"
                              title={t('SCREENS.SENDER_IDS.RBM_CONFIG')}
                              icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                              className="application-form__callout"
                              dense
                            >
                              {t('SCREENS.SENDER_IDS.RBM_CONFIG_DESC')}
                              <a href="https://google.com" target="_blank" rel="noreferrer noopener">
                                {`\n${window.location.origin.replace('-admin', '')}/google/sync?bot_id=${values.webHookGuid}`}
                              </a>
                              {t('SCREENS.SENDER_IDS.RBM_CONFIG_DESC_END')}
                            </Callout>
                          )
                        }
                        {
                          values.vendorType === 'alaris_gsma' && (
                            <Callout
                              view="smooth"
                              title={t('SCREENS.SENDER_IDS.GSMA_CONFIG')}
                              icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                              className="application-form__callout"
                              dense
                            >
                              {t('SCREENS.SENDER_IDS.GSMA_CONFIG_DESC')}
                              <a href="https://google.com" target="_blank" rel="noreferrer noopener">
                                {`\n${window.location.origin.replace('-admin', '')}/google/sync?bot_id=${values.webHookGuid}`}
                              </a>
                            </Callout>
                          )
                        }
                      </div>
                    </div>
                  )}
                />
              </Tabs>
            )
          }
          {
            readOnly && currentUserId === initialValues.managerUserId && initialValues.status === 1 && (
              <div className="application-form__callout-wrapper">
                <Callout
                  view="smooth"
                  title={t('SCREENS.APPLICATIONS.APPLICATION_ASSIGNED')}
                  icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                  className="application-form__callout"
                  dense
                >
                  {t('SCREENS.APPLICATIONS.APPLICATION_ASSIGNED_DESC')}
                </Callout>
              </div>
            )
          }
          <Footer
            onLeftButtonClick={onLeftButtonClick}
            onCancel={onCancel}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            leftButtonText={leftButtonText}
            rightButtonText={rightButtonText}
            onRightButtonClick={handleSubmit}
            submitDisabled={false}
            showControls={showControls}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <OnChangeComponent field="companyId" onChange={onChangeCompany} />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationForm;
