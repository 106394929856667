import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const OnFormChange = ({
  callback,
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    callback(values);
  }, [values]);

  return null;
};

export default OnFormChange;
