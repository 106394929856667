import React from 'react';
import classNames from 'classnames';

import Button from '@setproduct-ui/core/Button/Button';
import { Typography } from 'components';

import './style.scss';

export default ({
  onDelete,
  onCancel,
  onApply,
  mode,
  setFieldTouched,
  errors,
  isPending,
  styles = {},
  showShadow,
  children,
  deleteTitle = '',
  showDelete = true,
  showSave = true,
  disabledDelete,
  additionalLeftButton = null,
  additionalRightButton = null,
  saveButtonText = 'CONTROLS.SAVE',
}) => {
  const className = classNames('form-buttons-group', {
    'form-buttons-group_shadow': showShadow,
  });

  const onApplyClick = () => {
    onApply();
    if (mode !== 'view') {
      Object.keys(errors).map(i => setFieldTouched(i, true));
    }
  };

  return (
    <div className={className} style={styles.container}>
      {children}
      <div className="form-buttons-group__buttons">
        {additionalLeftButton}
        {
          mode !== 'add' && showDelete && (
            <Button
              type="default"
              view="outlined"
              color="danger"
              onClick={onDelete}
              disabled={disabledDelete || isPending}
              data-testid={`${window.location.pathname}-delete-instance-from-modal`}
            >
              <Typography color="danger" colorStep={50}>
                {deleteTitle || 'CONTROLS.DELETE'}
              </Typography>
            </Button>
          )
        }
        <div className="form-buttons-group__right-buttons">
          <Button
            type="default"
            view="outlined"
            color="primary"
            onClick={onCancel}
            data-testid={`${window.location.pathname}/close-modal`}
          >
            <Typography color="primary" colorStep={50}>
              CONTROLS.CANCEL
            </Typography>
          </Button>
          {additionalRightButton}
          {showSave && (
            <Button
              type="default"
              view={mode === 'view' ? 'outlined' : 'filled'}
              color="primary"
              onClick={onApplyClick}
              disabled={isPending}
              data-testid={`${window.location.pathname}/save-instance`}
            >
              <Typography
                color="primary"
                colorStep={mode === 'view' ? 50 : 0}
              >
                {mode === 'view' ? 'CONTROLS.EDIT' : saveButtonText}
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
