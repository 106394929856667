import React from 'react';
import cx from 'classnames';

import styles from './AddRowButton.module.scss';

import { ReactComponent as CirclePlus } from '../icons/circle-plus.svg';

const AddButtonRow = ({
  onClick,
  activeRow,
}) => (
  <div style={{ display: 'contents' }}>
    <div />
    <div className={styles.buttonCellWrapper}>
      <CirclePlus
        onClick={onClick}
        className={cx(styles.button, {
          [styles.button_disabled]: !!activeRow,
        })}
        title={activeRow ? 'Keep or delete the previous node before adding a new one' : ''}
      />
    </div>
  </div>
);

export default AddButtonRow;
