import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getSmppConnections as getSmppConnectionsApi,
  deleteSmppConnection as deleteSmppConnectionApi,
  putSmppConnection as putSmppConnectionApi,
  postSmppConnection as postSmppConnectionApi,
} from 'queries/smppConnections';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSmppConnections,
      lastUpdated: lastUpdatedGetSmppConnections,
    },
    getSmppConnections,
  ] = useMutation(getSmppConnectionsApi);
  const [
    {
      isPending: isPendingDeleteSmppConnection,
    },
    deleteSmppConnection,
  ] = useMutation(deleteSmppConnectionApi);
  const [
    {
      isPending: isPendingPutSmppConnection,
    },
    putSmppConnection,
  ] = useMutation(putSmppConnectionApi);
  const [
    {
      isPending: isPendingPostSmppConnection,
    },
    postSmppConnection,
  ] = useMutation(postSmppConnectionApi);

  const selector = useCallback(state => ({
    smppConnections: getArray(state, 'smppConnections'),
    smppConnectionsMeta: getObject(state, 'smppConnectionsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getSmppConnections,
    deleteSmppConnection,
    putSmppConnection,
    postSmppConnection,
    isPendingGetSmppConnections,
    isPendingDeleteSmppConnection,
    isPendingPutSmppConnection,
    isPendingPostSmppConnection,
    lastUpdatedGetSmppConnections,
    ...data,
  };
};
