import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getAccounts as getAccountsApi,
  deleteAccount as deleteAccountApi,
  putAccount as putAccountApi,
  postAccount as postAccountApi,
  getApiCalls as getApiCallsApi,
  getOperations as getOperationsApi,
} from 'queries/accounts';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetAccounts,
      lastUpdated: lastUpdatedGetAccounts,
    },
    getAccounts,
  ] = useMutation(getAccountsApi);
  const [
    {
      isPending: isPendingDeleteAccount,
    },
    deleteAccount,
  ] = useMutation(deleteAccountApi);
  const [
    {
      isPending: isPendingPutAccount,
    },
    putAccount,
  ] = useMutation(putAccountApi);
  const [
    {
      isPending: isPendingPostAccount,
    },
    postAccount,
  ] = useMutation(postAccountApi);
  const [
    {
      isPending: isPendingGetApiCalls,
      lastUpdated: lastUpdatedGetApiCalls,
    },
    getApiCalls,
  ] = useMutation(getApiCallsApi);
  const [
    {
      isPending: isPendingGetOperations,
      lastUpdated: lastUpdatedGetOperations,
    },
    getOperations,
  ] = useMutation(getOperationsApi);

  const selector = useCallback(state => ({
    accounts: getArray(state, 'accounts'),
    accountsAll: getArray(state, 'accountsAll'),
    accountsMeta: getObject(state, 'accountsMeta'),
    apiCalls: getArray(state, 'apiCalls'),
    operations: getArray(state, 'operations'),
    apiCallsMeta: getObject(state, 'apiCallsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getAccounts,
    getOperations,
    getApiCalls,
    deleteAccount,
    putAccount,
    postAccount,
    isPendingGetApiCalls,
    isPendingGetOperations,
    isPendingGetAccounts,
    isPendingDeleteAccount,
    isPendingPutAccount,
    isPendingPostAccount,
    lastUpdatedGetAccounts,
    lastUpdatedGetOperations,
    lastUpdatedGetApiCalls,
    ...data,
  };
};
